import { Button, Drawer } from "antd";
import React, { useState } from "react";
import WaiterProductList from "./WaiterProductList";

type WaiterTableProductAddCompProps={
  tableId:string;
}
const WaiterTableProductAddComp:React.FC<WaiterTableProductAddCompProps> = ({tableId}) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="primary" variant="filled" onClick={showDrawer}>
        Sipariş Gir
      </Button>
      <Drawer
        title={
          <div className="row">
            <div className="col">Sipariş Oluştur</div>
          
          </div>
        }
        placement="bottom"
        onClose={onClose}
        open={open}
      >
        <WaiterProductList tableId={tableId} />
      </Drawer>
    </div>
  );
};

export default WaiterTableProductAddComp;
