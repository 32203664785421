import { Button } from "antd";
import Title from "antd/es/typography/Title";
import "./counter.css";
import { useSelector } from "react-redux";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import React from "react";

interface CounterComponentProps {
  count: number;
  increment: () => void;
  descrement: () => void;
  color?: string;
  backgroundcolor?: string;
  backgroundbtn?: string;
  bordercolor?: string;
}
const CounterComponent: React.FC<CounterComponentProps> = ({
  count = 1,
  increment = () => {},
  descrement = () => {},
  color = "rgba(194, 193, 193, 0.884)",
  backgroundcolor = "#2a283d00",
  bordercolor = "transparent",
  backgroundbtn = "#d9d9d900",
}) => {
  const company = useSelector(getCompany);

  if (company?.orderAtTheTable) {
    return (
      <div className="counter-content">
        <Button
          style={{
            fontSize: "smaller",
            background: backgroundbtn,
            borderColor: bordercolor,
          }}
          size="small"
          icon={
            <i style={{ color: color }} className="fa-regular fa-minus"></i>
          }
          onClick={descrement}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: backgroundcolor,
            borderRadius: 5,
          }}
        >
          <Title
            className="counter"
            style={{ fontSize: "smaller", color: color }}
            level={5}
          >
            {count}
          </Title>
        </div>
        <Button
          style={{
            fontSize: "smaller",
            background: backgroundbtn,
            borderColor: "#d9d9d900",
          }}
          size="small"
          icon={<i style={{ color: color }} className="fa-sharp fa-solid fa-plus fa-lg"></i>}
          onClick={increment}
        />
      </div>
    );
  }

  return <></>;
};

export default CounterComponent;
