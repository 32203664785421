import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { getProductList } from "../../../../react-reduxs/slices/products/product_slice";
import { getCategoryList } from "../../../../react-reduxs/slices/categories/category_slice";
import { Avatar, Input, List } from "antd";
import { ApiUrl } from "../../../../consts/api";
import WaiterCategoryList from "./WaiterCategoryList";
import { SearchOutlined } from "@ant-design/icons";
import "./waiter-product.css";
import WaiterProductAddToBasket from "./WaiterProductAddToBasket";
import ButtonPrinter from "../../../printers/ButtonPrinter";
import { getPrinterList } from "../../../../react-reduxs/slices/printers/printer_slice";

type WaiterProductListProps = {
  tableId: string;
};

const WaiterProductList: React.FC<WaiterProductListProps> = ({ tableId }) => {
  const productList = useSelector(getProductList);
  const categoryList = useSelector(getCategoryList);
  const printerList = useSelector(getPrinterList);
  const [search, setSearch] = useState("");

  const [selectedCategoryId, setSelectedCategoryId] = useState(
    categoryList[0]?.id ?? 0
  );
  const onChangeSelectCategory = useCallback((id: number) => {
    setSelectedCategoryId(id);
  }, []);

  return (
    <div>
      <WaiterCategoryList
        selectedCategoryId={selectedCategoryId}
        onChangeSelectCategory={onChangeSelectCategory}
        categoryList={categoryList}
      />
      <Input
        size="small"
        style={{ marginTop: 10 }}
        placeholder="Ara"
        addonBefore={<SearchOutlined />}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <List
        dataSource={productList
          .filter((x) => x.menuCategoryId === selectedCategoryId)
          .filter((x) =>
            x.title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          )}
        pagination={{
          onChange: (page) => {},
          pageSize: 3,
        }}
        renderItem={(item) => {
          const printer = printerList.find((x) => x.id === item.printerId);
          return (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={
                      ApiUrl +
                      "/" +
                      (item.photo === undefined || item.photo === ""
                        ? "/images/product.png"
                        : item.photo)
                    }
                  />
                }
                title={<a>{item.title}</a>}
                description={
                  <div>
                    <div className="text-ellipsis ">{item.detail}</div>
                    <WaiterProductAddToBasket
                      product={item}
                      tableId={tableId}
                    />
                    {printer ? (
                      <ButtonPrinter
                        ipAddress={printer.printerIpAddress}
                        port={parseInt(printer.printerPort ?? "0")}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default WaiterProductList;
