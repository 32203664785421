import BaseApiService from "../base_api.service";
import { MenuProductModel } from "./models/menu_product.model";

class MenuProductServcie extends BaseApiService {
  constructor() {
    super("MenuProducts");
  }
  async getList(companyId: number): Promise<MenuProductModel[]> {
    return (await this.get(`?companyId=${companyId}`)) ?? [];
  }
  async getListByStatus(
    companyId: number,
    status: boolean = true
  ): Promise<MenuProductModel[]> {
    return (await this.get(`/GetListByStatus?companyId=${companyId}&status=${status}`)) ?? [];
  }
  add(
    menuProductModel: MenuProductModel
  ): Promise<MenuProductModel | undefined> {
    return this.post<MenuProductModel>(``, menuProductModel);
  }
  getById(id: number): Promise<MenuProductModel | undefined> {
    return this.get<MenuProductModel>(`/${id}`);
  }
  update(
    menuProductModel: MenuProductModel
  ): Promise<MenuProductModel | undefined> {
    return this.put<MenuProductModel>(``, menuProductModel);
  }
  deleteProduct(id: number): Promise<string | undefined> {
    return this.delete<string>(`?id=${id}`);
  }
}
export default MenuProductServcie;
