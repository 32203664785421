import { Button, Col, Form, Input, message, Row } from "antd";
import Title from "antd/es/typography/Title";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { useSelector } from "react-redux";
import { PrinterService } from "../../../services/printers/printer.service";

interface CompanyMngrPrinterAddProps {
  id: number;
}

const CompanyMngrPrinterAdd: React.FC<CompanyMngrPrinterAddProps> = ({
  id,
}) => {
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const _printerService = useMemo(() => new PrinterService(), []);
  const [form] = Form.useForm();
  const companyId = useSelector(getCompanyId);
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = async (values: any) => {
    setIsLoading(true);
    if (values.id > 0) {
      const response = await _printerService.update({
        ...values,
      });
      if (response) {
        messageApi.success("Kayıt Başarılı.");
        form.resetFields();
      }
    } else {
      const response = await _printerService.add({
        companyId: companyId,
        ...values,
      });
      if (response) {
        messageApi.success("Kayıt Başarılı.");
        form.resetFields();
      }
    }

    setIsLoading(false);
  };
  const initialLoad = useCallback(async () => {
    const response = await _printerService.getById(id);
    console.error(response);

    if (response) {
      form.setFieldsValue({ ...response });
    }
  }, []);

  useEffect(() => {
    setCount((cou) => {
      if (cou === 0) {
        if (id !== 0) {
          initialLoad();
        }
      }
      return 1;
    });
    return () => {};
  }, []);

  return (
    <div>
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row style={{ gap: 3 }}>
          <Col flex={1}>
            <Title style={{ lineHeight: 0 }} level={3}>
              Yazıcı Ekle
            </Title>
            <Form.Item
              style={{ position: "relative", height: 0 }}
              name="id"
              label=""
            >
              <Input placeholder="" style={{ display: "none" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex={1}>
            <Form.Item name="name" label="Adı" rules={[{ required: true }]}>
              <Input placeholder="Adı" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col flex={3}>
            <Form.Item
              name="printerIpAddress"
              label="Ip Adresi"
              rules={[{ required: true }]}
            >
              <Input placeholder="Ip Adresi" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="printerPort"
              label="Port"
              rules={[{ required: true }]}
            >
              <Input placeholder="Port" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              loading={isLoading}
              htmlType="submit"
              size="small"
              icon={<i className="fa-solid fa-floppy-disk"></i>}
            >
              Kaydet
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CompanyMngrPrinterAdd;
