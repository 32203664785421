import React, { useCallback, useEffect, useRef, useState } from "react";
import { SectionService } from "../../../services/sections/serction.service";
import { SectionModel } from "../../../services/sections/models/section.model";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import {
  Button,
  Col,
  Input,
  List,
  Modal,
  Popconfirm,
  Row,
  Table,
  TableColumnsType,
} from "antd";
import { toast } from "react-toastify";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";
import { ApiUrl } from "../../../consts/api";
import dayjs from "dayjs";
import Title from "antd/es/typography/Title";
import CompManagerSectionAdd from "./CompManagerSectionAdd";
import { SearchOutlined } from "@ant-design/icons";

const CompanyManagerSectionList = () => {
  const companyId = useSelector(getCompanyId);

  const sectionService = new SectionService();
  const fileUploadService = new FileUploadService();

  const section = useRef<SectionModel>();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [modalVisible, setModalVisible] = useState(false);
  const [sectionList, setSectionList] = useState<SectionModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const tempSectionList = useRef<SectionModel[]>([]);
  const getSectionList = useCallback(async () => {
    let response = await sectionService.getListByCompanyId(companyId);
    if (response) {
      setSectionList(response);
      tempSectionList.current = response;
    }
  }, [companyId, sectionService]);

  useEffect(() => {
    setCount((co) => {
      if (co === 0) {
        getSectionList();
      }
      return 1;
    });
    return () => {};
  }, [getSectionList]);

  const addSection = useCallback(
    async (sectionModel: SectionModel) => {
      setIsLoading(true);
      if (sectionModel.id === 0 || sectionModel.id === undefined) {
        let response = await sectionService.add(sectionModel);
        if (response) {
          toast.success("Kayıt işlemi başarılı");
          await getSectionList();
        }
      } else {
        let response = await sectionService.update(sectionModel);
        if (response) {
          toast.success("Kayıt işlemi başarılı");
          await getSectionList();
        }
      }
      setModalVisible(false);
      setIsLoading(false);
    },
    [getSectionList, sectionService]
  );
  const deleteImage = useCallback(
    async (path: string, sectionModel: SectionModel) => {
      setIsLoading(true);
      sectionModel.logo = undefined;
      let response = await sectionService.update(sectionModel);
      if (response) {
        let response2 = await fileUploadService.deleteFile(path);
        if (response2) {
          toast.success("Silme işlemi başarılı");
        } else {
          sectionModel.logo = path;
          let response3 = await sectionService.update(sectionModel);

          return response3;
        }
        setIsLoading(false);
        return response;
      }
      setIsLoading(false);
      return sectionModel;
    },
    [fileUploadService, sectionService]
  );
  const onChangeSearch = useCallback((e: string) => {
    if (e.length > 0) {
      setSectionList(
        tempSectionList.current.filter((x) =>
          x.name.toLocaleLowerCase().includes(e.toLocaleLowerCase())
        )
      );
    } else {
      setSectionList(tempSectionList.current);
    }
  }, []);
  const columns: TableColumnsType<SectionModel> = [
    {
      width: 100,
      title: "Fotoğraf",
      dataIndex: "logo",
      render: (value, record, index) => {
        return (
          <img
            width={70}
            height={70}
            src={ApiUrl + "/" + value ?? "/images/product.png"}
            alt=""
          />
        );
      },
    },
    {
      title: "Adı",
      dataIndex: "name",
    },

    {
      title: "Ekleme Zamanı",
      width: 200,
      dataIndex: "createdDate",
      render: (value) => {
        return <div>{dayjs(value).format("DD/MM/YYYY HH:mm")}</div>;
      },
    },
    {
      title: "Güncellenme Zamanı",
      dataIndex: "updatedDate",
    },

    {
      width: 50,
      title: "",
      dataIndex: "id",
      render: (value, record) => {
        return (
          <Button
            loading={isLoading}
            disabled={isLoading}
            type="text"
            onClick={() => {
              section.current = record;
              setModalVisible(true);
            }}
            style={{ backgroundColor: "#C4F3F7B2" }}
            icon={
              <i
                style={{ color: "green", fontSize: "large" }}
                className="fa-duotone fa-pen-to-square"
              ></i>
            }
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      width: 70,
      render(value, record) {
        return (
          <Popconfirm
            placement="left"
            title={"Kayıdı silinecek onaylıyormusunuz ?"}
            okText="Evet"
            cancelText="Hayır"
            onConfirm={async () => {
              setIsLoading(true);
              var response = await sectionService.deleteById(value);
              if (response) {
                if (record.logo !== undefined && record.logo !== "") {
                  await fileUploadService.deleteFile(record.logo);
                }
                toast.success("Silme başarılı");
              }
              await getSectionList();
              setIsLoading(false);
            }}
          >
            <Button
              loading={isLoading}
              disabled={isLoading}
              danger
              icon={<i className="fa-duotone fa-trash"></i>}
            />
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <div>
      <Title level={3} className="my-title">
        Ürün Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <Button
            loading={isLoading}
            onClick={() => {
              section.current = undefined;
              setModalVisible(true);
            }}
            size="small"
            icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
          >
            Yeni Bölüm Ekle
          </Button>
        </Col>
        <Col>
          <Input
            size="small"
            addonAfter={<SearchOutlined />}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
          />
        </Col>
      </Row>

      {windowSize.width > 605 ? (
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={sectionList}
          rowKey={"id"}
          pagination={{ pageSize: 10 }}
          virtual={true}
          style={{ maxHeight: "70vh", overflow: "auto" }}
        />
      ) : (
        <List
          itemLayout="vertical"
          size="small"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 3,
          }}
          dataSource={sectionList}
          renderItem={(item) => (
            <List.Item
              key={item.name}
              actions={[
                <Button
                  key={1}
                  loading={isLoading}
                  disabled={isLoading}
                  type="text"
                  onClick={() => {
                    section.current = item;
                    setModalVisible(true);
                  }}
                  style={{ backgroundColor: "#C4F3F7B2" }}
                  icon={
                    <i
                      style={{ color: "green", fontSize: "large" }}
                      className="fa-duotone fa-pen-to-square"
                    ></i>
                  }
                />,
                <Popconfirm
                  key={2}
                  placement="left"
                  title={"Kayıdı silinecek onaylıyormusunuz ?"}
                  okText="Evet"
                  cancelText="Hayır"
                  onConfirm={async () => {
                    setIsLoading(true);
                    var response = await sectionService.deleteById(item.id);
                    if (response) {
                      if (item.logo !== undefined && item.logo !== "") {
                        await fileUploadService.deleteFile(item.logo);
                      }
                      toast.success("Silme başarılı");
                    }
                    await getSectionList();
                    setIsLoading(false);
                  }}
                >
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    danger
                    icon={<i className="fa-duotone fa-trash"></i>}
                  />
                </Popconfirm>,
              ]}
              extra={
                <img
                  className="ant-list-item-img"
                  alt="logo"
                  src={
                    item.logo
                      ? ApiUrl + `/` + "/" + item.logo
                      : "/images/product.png"
                  }
                />
              }
            >
              <List.Item.Meta title={item.name} />
            </List.Item>
          )}
        />
      )}
      {modalVisible ? (
        <Modal footer={<></>} closable={false} open={modalVisible}>
          <CompManagerSectionAdd
            isLoading={isLoading}
            addSection={addSection}
            sectionModel={section.current}
            deleteMenuSectionImage={deleteImage}
            closeModal={() => setModalVisible(false)}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompanyManagerSectionList;
