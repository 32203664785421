import { Flex, Tag } from "antd";
import React from "react";
import { MenuCategoryModel } from "../../../../services/menu_categories/models/menu_category.model";

interface WaiterCategoryListProps {
  selectedCategoryId: number;
  categoryList: MenuCategoryModel[];
  onChangeSelectCategory: (id: number) => void;
}

const WaiterCategoryList: React.FC<WaiterCategoryListProps> = ({
  categoryList,
  selectedCategoryId,
  onChangeSelectCategory,
}) => {
  return (
    <div>
      <Flex gap={4} wrap align="center">
        {categoryList.map<React.ReactNode>((category) => (
          <Tag.CheckableTag
            key={category.id}
            checked={selectedCategoryId === category.id}
            onChange={() => onChangeSelectCategory(category.id)}
          >
            {category.name}
          </Tag.CheckableTag>
        ))}
      </Flex>
    </div>
  );
};

export default WaiterCategoryList;
