import { Col, Row, Transfer } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OperationClaimModel } from "../../../services/operation_claims/models/operation_claim.model";
import { OperationClaimService } from "../../../services/operation_claims/operation_claim.service";
import { GroupClaimService } from "../../../services/group_claims/group_claim.service";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Title from "antd/es/typography/Title";
import BackButton from "../../../components/BackButton";

const AdminGroupOperationClaim: React.FC = () => {
  const operationClaimService = useMemo(() => new OperationClaimService(), []);
  const groupClaimService = useMemo(() => new GroupClaimService(), []);

  const { id } = useParams();
  const [mockData, setMockData] = useState<OperationClaimModel[]>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [count, setCount] = useState<number>(0);

  const getGroupClaim = useCallback(async () => {
    let response = await groupClaimService.getByGroupId(parseInt(id ?? "0"));

    if (response) {
      setTargetKeys(
        response.map((x) => {
          return x.id;
        })
      );
    }
  }, [groupClaimService, id]);

  const getOperationClaim = useCallback(async () => {
    let response = await operationClaimService.list();
    if (response) {
      setMockData(response);
    }
  }, [operationClaimService]);

  const intialLoad = useCallback(async () => {
    await getGroupClaim();
    await getOperationClaim();
  }, [getGroupClaim, getOperationClaim]);

  const setGroupOperationClaim = useCallback(
    async (operationClaimList: string[]) => {
      if (id) {
        let newList: number[] = [];
        if (operationClaimList) {
          operationClaimList.forEach((element) => {
            let val = parseInt(element);
            if (val) {
              newList.push(val);
            }
          });
          if (newList) {
            let response = await groupClaimService.add(
              newList,
              parseInt(id ?? "0")
            );
            if (response) {
              toast.success(response);
              await getGroupClaim();
            }
          }
        }
      }
    },
    [getGroupClaim, groupClaimService, id]
  );
  const deleteClaim = useCallback(
    async (ids: number[]) => {
      let idp = id?.toString();

      var response = await groupClaimService.deleted(ids, parseInt(idp ?? "0"));
      if (response) {
        toast.success(response);
        await getGroupClaim();
      }
    },
    [getGroupClaim, groupClaimService, id]
  );

  useEffect(() => {
    if (id) {
      if (parseInt(id)) {
        setCount((co) => {
          if (co === 0) {
            intialLoad();
          }
          return 1;
        });
      }
    }

    return () => {};
  }, [id, intialLoad]);

  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <BackButton />
        </Col>
        <Col flex={1}>
          <Title
            level={3}
            style={{ color: "#4A5967", textAlign: "center", margin: 0 }}
          >
            Grub Yetki Tanımları
          </Title>
        </Col>
      </Row>

      <Transfer
        dataSource={mockData}
        targetKeys={targetKeys}
        rowKey={(value) => value.id.toString()}
        onChange={async (value, direction, moveKeys) => {
          if (direction === "right") {
            setGroupOperationClaim(moveKeys as string[]);
          }
          if (direction === "left") {
            let numberIdList: number[] = [];
            (moveKeys as string[]).forEach((element) => {
              let idP = parseInt(element);
              if (idP) {
                numberIdList.push(idP);
              }
            });
            await deleteClaim(numberIdList);
          }
        }}
        render={(item) => item.name}
        pagination
      />
    </div>
  );
};

export default AdminGroupOperationClaim;
