import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AdminCompanyUserAdd from "../../admins/company/AdminCompanyUserAdd";
import {
  Button,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Table,
  TableColumnsType,
} from "antd";
import { Provider, useSelector } from "react-redux";
import { store } from "../../../react-reduxs/configure_store";
import dayjs from "dayjs";
import { UserListModel } from "../../../services/users/models/user_list.model";
import Title from "antd/es/typography/Title";
import { UserService } from "../../../services/users/user.service";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const CompManagerUserList = () => {
  const companyId = useSelector(getCompanyId);
  const userService = useMemo(() => new UserService(), []);
  const [data, setData] = useState<UserListModel[]>([]);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const tempUserList = useRef<UserListModel[]>([]);
  const columns: TableColumnsType<UserListModel> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Kullanıcı Adı",
      dataIndex: "userName",
    },
    {
      title: "E-Posta",
      dataIndex: "email",
    },
    {
      title: "Kullanıcı Grubu",
      dataIndex: "groupName",
      render: (value) => {
        return t(`template.${value}`);
      },
    },
    {
      title: "Oluşturulma Tarihi",
      dataIndex: "createdDate",
      render: (value) => {
        return <>{dayjs(value).format("DD/MM/YYYY HH:mm")}</>;
      },
    },
    {
      title: "Güncelleme Tarihi",
      dataIndex: "updatedDate",
      render: (value) => {
        return <>{dayjs(value).format("DD/MM/YYYY HH:mm")}</>;
      },
    },
    {
      width: 50,
      title: "Kullanıcı Durumu",
      dataIndex: "id",
      render: (value, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              onClick={async () => {
                if (record.deletedDate === undefined) {
                  await userService.disableUser(value, dayjs().format());
                  await getUserList();
                } else {
                  await userService.disableUser(value, null);
                  await getUserList();
                }
              }}
              checked={record.deletedDate === undefined}
            />
          </div>
        );
      },
    },
  ];
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const getUserList = useCallback(async () => {
    setIsLoading(true);
    let response = await userService.getListByCompanyId(companyId);

    if (response) {
      tempUserList.current = response;
      setData(response);
    }
    setIsLoading(false);
  }, [companyId, userService]);
  useEffect(() => {
    if (companyId !== 0) {
      setCount((cou) => {
        if (cou === 0) {
          getUserList();
        }
        return 1;
      });
    }

    return () => {};
  }, [companyId, getUserList]);
  const onChangeSearch = useCallback((e: string) => {
    if (e.length > 0) {
      setData(
        tempUserList.current.filter(
          (x) =>
            x.email.toLocaleLowerCase().includes(e.toLocaleLowerCase()) ||
            x.userName.toLocaleLowerCase().includes(e.toLocaleLowerCase())
        )
      );
    } else {
      setData(tempUserList.current);
    }
  }, []);
  return (
    <div>
      <Title level={3} className="my-title">
        Kullanıcı Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <Button
            size="small"
            type="primary"
            loading={isLoading}
            disabled={isLoading}
            onClick={() => {
              Modal.info({
                okType: "danger",
                okText: "İptal",

                content: (
                  <Provider store={store}>
                    <AdminCompanyUserAdd
                      isLoading={isLoading}
                      setIsloading={setIsLoading}
                      companyId={companyId}
                      getList={() => getUserList()}
                      compManager={true}
                    />
                  </Provider>
                ),
              });
            }}
          >
            Kullanıcı Ekle
          </Button>
        </Col>
        <Col>
          <Input
            size="small"
            addonAfter={<SearchOutlined />}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      {windowSize.width > 605 ? (
        <Table columns={columns} dataSource={data} rowKey={"id"} />
      ) : (
        <List
          itemLayout="vertical"
          size="small"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 3,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item style={{
              borderColor:"#8080808a",
              borderWidth:"thin"
            }}
              key={item.userName}
              actions={[
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Checkbox
                    onClick={async () => {
                      if (item.deletedDate === undefined) {
                        await userService.disableUser(
                          item.id,
                          dayjs().format()
                        );
                        await getUserList();
                      } else {
                        await userService.disableUser(item.id, null);
                        await getUserList();
                      }
                    }}
                    checked={item.deletedDate === undefined}
                  />
                </div>,
              ]}
            >
              <List.Item.Meta
                title={item.userName}
                description={
                  <div style={{ display: "flex", gap: 5,justifyContent:"center",alignItems:"center" }}>
                    <b>Kullanıcı Epostası:</b><p>{item.email}</p>
                    <b>Kullanıcı Grubu:</b><p>{t(`template.${item.groupName}`)}</p>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default CompManagerUserList;
