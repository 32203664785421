import * as signalR from "@microsoft/signalr";
import { Input, message, Popconfirm } from "antd";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaPaperPlane } from "react-icons/fa";
import { useSelector } from "react-redux";
import { OrderHubUrl } from "../../../../../consts/api";
import {
  OrderStatusEnum,
  OrderProductStatusEnum,
} from "../../../../../enums/orders/order_status.enum";
import { getCompany } from "../../../../../react-reduxs/slices/companies/company_slice";
import { MenuProductAmountType } from "../../../../../models/menu_product_amount_type";
import { MenuProductModel } from "../../../../../services/menu_products/models/menu_product.model";
import { I18nPagePropertyEnum } from "../../../../../i18n/i18n_page_property";

interface T4OrderAddBtnComponentProps {
  menuProductAmountList: MenuProductAmountType[];
  menuProduct: MenuProductModel;
  tableId: string;
  removeMenuProductAmount: any;
  title?: string;
}
const T4OrderAddBtnComponent: React.FC<T4OrderAddBtnComponentProps> = ({
  menuProductAmountList = [],
  menuProduct,
  tableId,
  removeMenuProductAmount,
  title = "Sipariş Ver",
}) => {
  const company = useSelector(getCompany);
  const { t } = useTranslation();
  const [orderNote, setOrderNote] = useState("");
  const [orderPopConfirmVisible, setOrderPopConfirmVisible] =
    useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(OrderHubUrl(company?.id ?? 0).url)
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  const senOrder = useCallback(async () => {
    let amount =
      menuProductAmountList.find((x) => x.menuProductId === menuProduct.id)
        ?.amount ?? 1;
    await connection
      .start()
      .then(() => {
        console.log("SignalR bağlantısı kuruldu.");
      })
      .catch((err) => {
        console.error(err.toString());
        connection.stop();
      });
    try {
      await connection.invoke(
        "PlaceOrder",
        {
          tableId: tableId,
          currencyId: company?.currencyId,
          companyId: company?.id,
          mobilePhone: "",
          status: OrderStatusEnum.paymentNotMade,
        },
        [
          {
            amount: amount,
            menuProductId: menuProduct.id,
            menuProductName: menuProduct.title,
            menuProductPhoto: menuProduct.photo,
            menuProductPrice: menuProduct.price,
            status: OrderProductStatusEnum.pendingApproval,
            orderNote: orderNote,
          },
        ]
      );
      removeMenuProductAmount(menuProduct.id);
      messageApi.open({
        type: "success",
        content: `${amount} Sipariş alındı.`,
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: `Sipariş Gönderilemedi ${error}`,
      });
    } finally {
      connection.stop();
      setOrderPopConfirmVisible(false);
    }
  }, [orderNote]);
  return (
    <div>
      {contextHolder}
      {company?.orderAtTheTable ? (
        <button className="btn" onClick={()=>{
          setOrderPopConfirmVisible(true);
        }}>
          <FaPaperPlane /> {title}
        </button>
      ) : (
        <></>
      )}

      <Popconfirm
        cancelText={t(I18nPagePropertyEnum.commonCancel)}
        okText={t(I18nPagePropertyEnum.commonOk)}
        onCancel={() => {
          setOrderPopConfirmVisible(false);
          setOrderNote("");
        }}
        icon={
          <i
            style={{ color: "green", marginRight: 5 }}
            className="fa-duotone fa-bell-concierge"
          ></i>
        }
        title={"Sipariş Ver "}
        description={
          <>
            {t(I18nPagePropertyEnum.menuProductOrderNote)}
            <Input.TextArea
              value={orderNote}
              onChange={(e) => setOrderNote(e.target.value)}
            />
          </>
        }
        open={orderPopConfirmVisible}
        onConfirm={senOrder}
      ></Popconfirm>
    </div>
  );
};

export default T4OrderAddBtnComponent;
