import BaseApiService from "../base_api.service";
import { FileUploadModel } from "./models/file_upload.model";

export class FileUploadService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("FileUploads");
  }
  async fileUpload(
    path: string,
    file: any
  ): Promise<FileUploadModel | undefined> {
    const formData = new FormData();
    formData.append("file", file);
    return await this.post<FileUploadModel>(`?filePath=${path}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  async fileUploadCompress(
    path: string,
    file: any
  ): Promise<FileUploadModel | undefined> {
    const formData = new FormData();
    formData.append("file", file);
    return await this.post<FileUploadModel>(`/UploadCompress?filePath=${path}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  deleteFile(path: string): Promise<any> {
    const encodedPath = encodeURIComponent(path);
    const url = `/DeleteFile?filePath=${encodedPath}`;
    console.error(url,encodedPath);
    
    return this.delete(url);
  }
}
