import {
  Button,
  Col,
  Input,
  List,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Table,
  TableColumnsType,
} from "antd";
import Title from "antd/es/typography/Title";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";
import MenuCategoryService from "../../../services/menu_categories/menu_category.service";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { toast } from "react-toastify";
import { ApiUrl } from "../../../consts/api";
import dayjs from "dayjs";
import CompManagerMenuCategoryAdd from "./CompManagerMenuCategoryAdd";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";
import { SearchOutlined } from "@ant-design/icons";

const CompanyManagerMenuCategoryList = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [categoryList, setCategoryList] = useState<MenuCategoryModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const companyId = useSelector(getCompanyId);
  const menuCategoryService = useMemo(() => new MenuCategoryService(), []);
  const fileUploadService = useMemo(() => new FileUploadService(), []);
  const categoryModel = useRef<MenuCategoryModel>();
  const tempCategoryList = useRef<MenuCategoryModel[]>([]);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const getCategory = useCallback(async () => {
    let response = await menuCategoryService.getList(companyId);

    if (response) {
      setCategoryList(response);
      tempCategoryList.current = response;
    }
  }, [companyId, menuCategoryService]);
  const init = useCallback(async () => {
    setIsLoading(true);
    await getCategory();
    setIsLoading(false);
  }, [getCategory]);
  useEffect(() => {
    setCount((c) => {
      if (c === 0) {
        init();
      }
      return 1;
    });

    return () => {};
  }, [init]);

  const saveCategory = useCallback(
    async (menuCategoryModel: MenuCategoryModel) => {
      setIsLoading(true);
      if (menuCategoryModel.id === 0 || menuCategoryModel.id === undefined) {
        let response = await menuCategoryService.add(menuCategoryModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");

          await getCategory();
        }
      } else {
        let response = await menuCategoryService.update(menuCategoryModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");

          await getCategory();
        }
      }
      setIsLoading(false);
    },
    [getCategory, menuCategoryService]
  );
  const onChangeSearch = useCallback((e: string) => {
    if (e.length > 0) {
      setCategoryList(
        tempCategoryList.current.filter((x) =>
          x.name.toLocaleLowerCase().includes(e.toLocaleLowerCase())
        )
      );
    } else {
      setCategoryList(tempCategoryList.current);
    }
  }, []);
  const columns: TableColumnsType<MenuCategoryModel> = [
    {
      width: 100,
      title: "Fotoğraf",
      dataIndex: "photo",
      render: (value) => {
        return (
          <img
            width={70}
            height={70}
            src={value ? ApiUrl + "/" + value : "/images/product.png"}
            alt=""
          />
        );
      },
    },

    {
      title: "Adı",
      dataIndex: "name",
    },
    {
      title: "Ekleme Zamanı",
      dataIndex: "createdDate",
      render: (value) => {
        return <div>{dayjs(value).format("DD/MM/YYYY HH:mm")}</div>;
      },
    },
    {
      title: "Güncellenme Zamanı",
      dataIndex: "updatedDate",
    },
    {
      title: "Durum",
      dataIndex: "status",
      width: 100,
      render: (value) => {
        return isLoading === false ? (
          <Switch
            checkedChildren="Aktif"
            disabled
            unCheckedChildren="Pasif"
            defaultChecked={value}
          />
        ) : (
          <></>
        );
      },
    },
    {
      width: 50,
      title: "",
      dataIndex: "id",
      render: (value, record) => {
        return (
          <Button
            loading={isLoading}
            disabled={isLoading}
            type="text"
            onClick={() => {
              categoryModel.current = record;
              setModalVisible(true);
            }}
            style={{ backgroundColor: "#C4F3F7B2" }}
            icon={
              <i
                style={{ color: "green", fontSize: "large" }}
                className="fa-duotone fa-pen-to-square"
              ></i>
            }
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      width: 70,
      render(value, record) {
        return (
          <Popconfirm
            placement="left"
            title={"Kayıdı silinecek onaylıyormusunuz ?"}
            okText="Evet"
            cancelText="Hayır"
            onConfirm={async () => {
              setIsLoading(true);
              let response = await menuCategoryService.deleteCategory(value);
              if (response) {
                if (record.photo !== undefined && record.photo !== "") {
                  await fileUploadService.deleteFile(record.photo);
                }
                toast.success("Silme başarılı");
              }
              await getCategory();
              setIsLoading(false);
            }}
          >
            <Button
              loading={isLoading}
              disabled={isLoading}
              style={{ marginRight: 10 }}
              danger
              icon={<i className="fa-duotone fa-trash"></i>}
            />
          </Popconfirm>
        );
      },
    },
  ];
  return (
    <div>
      <Title level={3} className="my-title" >
        Ürün Kategori Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <Button
            loading={isLoading}
            onClick={() => {
              setModalVisible(true);
              categoryModel.current = undefined;
            }}
            size="small"
            icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
          >
            Yeni Kategori Ekle
          </Button>
        </Col>
        <Col>
          <Input
            size="small"
            addonAfter={<SearchOutlined />}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
          />
        </Col>
      </Row>

      {windowSize.width > 605 ? (
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={categoryList}
          rowKey={"id"}
          pagination={{ pageSize: 10 }}
          virtual={true}
          style={{ maxHeight: "70vh", overflow: "auto" }}
        />
      ) : (
        <List
          itemLayout="vertical"
          size="small"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 3,
          }}
          dataSource={categoryList}
          renderItem={(item) => (
            <List.Item
              key={item.name}
              actions={[
                <Button
                  key={1}
                  loading={isLoading}
                  disabled={isLoading}
                  type="text"
                  onClick={() => {
                    categoryModel.current = item;
                    setModalVisible(true);
                  }}
                  style={{ backgroundColor: "#C4F3F7B2" }}
                  icon={
                    <i
                      style={{ color: "green", fontSize: "large" }}
                      className="fa-duotone fa-pen-to-square"
                    ></i>
                  }
                />,
                <Popconfirm
                  key={2}
                  placement="left"
                  title={"Kayıdı silinecek onaylıyormusunuz ?"}
                  okText="Evet"
                  cancelText="Hayır"
                  onConfirm={async () => {
                    setIsLoading(true);
                    let response = await menuCategoryService.deleteCategory(
                      item.id
                    );
                    if (response) {
                      if (item.photo !== undefined && item.photo !== "") {
                        await fileUploadService.deleteFile(item.photo);
                      }
                      toast.success("Silme başarılı");
                    }
                    await getCategory();
                    setIsLoading(false);
                  }}
                >
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    style={{ marginRight: 10 }}
                    danger
                    icon={<i className="fa-duotone fa-trash"></i>}
                  />
                </Popconfirm>,
                isLoading === false ? (
                  <Switch
                    checkedChildren="Aktif"
                    disabled
                    unCheckedChildren="Pasif"
                    defaultChecked={item.status}
                  />
                ) : (
                  <></>
                ),
              ]}
              extra={
                <img
                  className="ant-list-item-img"
                  alt="logo"
                  src={
                    item.photo
                      ? ApiUrl + `/` + "/" + item.photo
                      : "/images/product.png"
                  }
                />
              }
            >
              <List.Item.Meta title={item.name} />
            </List.Item>
          )}
        />
      )}

      {modalVisible ? (
        <Modal footer={<></>} open={modalVisible}>
          <CompManagerMenuCategoryAdd
            isLoading={isLoading}
            saveCategory={saveCategory}
            categoryModel={categoryModel.current}
            modalClose={() => {
              setModalVisible(false);
            }}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompanyManagerMenuCategoryList;
