import React from "react";
import { getCategoryList } from "../../../../react-reduxs/slices/categories/category_slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../react-reduxs/configure_store";
import {
  categoryIdChange,
  getProductSelectedCategoryId,
} from "../../../../react-reduxs/slices/products/product_slice";
import { ApiUrl } from "../../../../consts/api";

const Template4Category = () => {
  const categoryList = useSelector(getCategoryList);
  const dispatch: AppDispatch = useDispatch();
  const selectCategoryId = useSelector(getProductSelectedCategoryId);
  return (
    <div className="t4-category">
      {categoryList.map((x) => (
        <div
          key={x.id}
          onClick={() => dispatch(categoryIdChange(x.id))}
          className={
            selectCategoryId === x.id
              ? "t4-category-item select-category"
              : "t4-category-item"
          }
        >
          <img
            src={x.photo ? `${ApiUrl}/${x.photo}` : "/images/category.png"}
            alt=""
          />
          <p className="title">{x.name}</p>
        </div>
      ))}
    </div>
  );
};

export default Template4Category;
