import React, { lazy } from "react";
import "./App.scss";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MainLayout from "./pages/MainLayout";
import AdminLayout from "./pages/admins/AdminLayout";
import AdminHome from "./pages/admins/home/AdminHome";
import WaiterLayout from "./pages/waiters/WaiterLayout";
import WaiterHomePage from "./pages/waiters/home/WaiterHomePage";
import LoginPage from "./pages/auth/LoginPage";

import { UserTypeEnum } from "./enums/user_type.enum";
import Template2 from "./pages/companies/templates/Template2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Template3 from "./pages/companies/templates/template_3/Tempalte3";
import TemplateRedirect from "./pages/TemplateRedirect";
import AdminCompanyList from "./pages/admins/company/AdminCompanyList";
import PrivateRoute from "./private_route";
import AdminUserList from "./pages/admins/users/AdminUserList";
import AdminCurrencyList from "./pages/admins/currencies/AdminCurrencyList";
import AdminGroupList from "./pages/admins/groups/AdminGroupList";
import AdminCompManagerHome from "./pages/company_managers/home/AdminCompManagerHome";
import CompanyManagerLayout from "./pages/company_managers/CompanyManagerLayout";
import CompanyNotFound from "./pages/errors/CompanyNotFound";
import AdminGroupOperationClaim from "./pages/admins/groups/AdminGroupOperationClaim";
import CompManagerTableList from "./pages/company_managers/tables/CompManagerTableList";
import CompManagerMenuProductList from "./pages/company_managers/menu_products/CompManagerMenuProductList";
import CompManagerEdit from "./pages/company_managers/companies/CompManagerEdit";
import CompManagerUserList from "./pages/company_managers/users/CompManagerUserList";
import CompanyManagerMenuCategoryList from "./pages/company_managers/menu_categories/CompanyManagerMenuCategoryList";
import CompanyManagerSectionList from "./pages/company_managers/sections/CompanyManagerSectionList";
import AdminCompanyUserTransfer from "./pages/admins/company/AdminCompanyUserTransfer";
import PasswordReset from "./components/PasswordReset";
import Template4 from "./pages/companies/templates/template-4/Template4";
import CompanyMngrPrinterList from "./pages/company_managers/printers/CompanyMngrPrinterList";

const LazyProjects = lazy(
  () => import("./pages/companies/templates/QrTemplate1Page")
);

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "",
          element: <TemplateRedirect />,
        },
        {
          path: "password-reset",
          element: <PasswordReset />,
        },
        {
          path: ":tableId",

          element: <TemplateRedirect />,
        },
        {
          path: "qrmenu",

          element: <LazyProjects />,
        },
        {
          path: "template2/:tableId",
          element: <Template2 />,
        },
        {
          path: "template3/:tableId",
          element: <Template3 />,
        },
        {
          path: "template4/:tableId",
          element: <Template4 />,
        },
        {
          path: "login",
          element: <LoginPage />,
        },
        {
          path: "company-not-found",
          element: <CompanyNotFound />,
        },
      ],
    },
    {
      path: "/waiter/*",
      element: (
        <PrivateRoute userType={UserTypeEnum.Waiter}>
          <WaiterLayout />
        </PrivateRoute>
      ),

      children: [
        {
          path: "",
          element: <WaiterHomePage />,
        },
      ],
    },
    {
      path: "/admin/*",
      element: (
        <PrivateRoute userType={UserTypeEnum.Admin}>
          <AdminLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "",
          element: <AdminHome />,
        },
        {
          path: "companyList",
          element: <AdminCompanyList />,
        },
        {
          path: "company-user-transfer/:companyId",
          element: <AdminCompanyUserTransfer />,
        },
        {
          path: "user-list",
          element: <AdminUserList />,
        },
        {
          path: "group-list",
          element: <AdminGroupList />,
        },

        {
          path: "currency-list",
          element: <AdminCurrencyList />,
        },
        {
          path: "group-operation-claim/:id",
          element: <AdminGroupOperationClaim />,
        },
      ],
    },
    {
      path: "/compManager/*",
      element: (
        <PrivateRoute userType={UserTypeEnum.CompanyManager}>
          <CompanyManagerLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "",
          element: <AdminCompManagerHome />,
        },
        {
          path: "table-list",
          element: <CompManagerTableList />,
        },
        {
          path: "company-edit",
          element: <CompManagerEdit />,
        },
        {
          path: "section-list",
          element: <CompanyManagerSectionList />,
        },
        {
          path: "menu-product-list",
          element: <CompManagerMenuProductList />,
        },
        {
          path: "printer-list",
          element: <CompanyMngrPrinterList />,
        },
        {
          path: "menu-category-list",
          element: <CompanyManagerMenuCategoryList />,
        },
        {
          path: "company-edit",
          element: <CompManagerEdit />,
        },
        {
          path: "company-user-list",
          element: <CompManagerUserList />,
        },
      ],
    },
  ]);

  return (
    <>
      <ToastContainer />
      <RouterProvider router={router} />
    </>
  );
};

export default App;
