import { Button, Drawer, Flex } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OrderStatusService } from "../../../../services/order_statuses/order_status.service";
import { OrderStatusModel } from "../../../../services/order_statuses/models/order_status.model";
import { useTranslation } from "react-i18next";
import { OrderModel } from "../../../../services/orders/models/order.model";
import * as signalR from "@microsoft/signalr";
import { OrderHubUrl } from "../../../../consts/api";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../../react-reduxs/slices/auth/auth_slice";
import { OrderHubEnum } from "../../../../enums/order_hub.enum";
import { OrderStatusEnum } from "../../../../enums/orders/order_status.enum";
interface CloseOrderDrawerProps {
  order?: OrderModel;
}

const CloseOrderDrawer: React.FC<CloseOrderDrawerProps> = ({ order }) => {
  const orderStatusService = useMemo(() => new OrderStatusService(), []);
  const companyId = useSelector(getCompanyId);
  const { t } = useTranslation();
  const [orderStatusModelList, setOrderStatusModelList] = useState<
    OrderStatusModel[]
  >([]);
  const [count, setCount] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const init = useCallback(async () => {
    var response = await orderStatusService.getList();
    if (response) {
      setOrderStatusModelList(response);
    }
  }, [orderStatusService]);

  useEffect(() => {
    setCount((cou) => {
      if (cou === 0) {
        init();
      }
      return 1;
    });
    return () => {};
  }, []);

  const closeOrder = useCallback(
    async (orderStatusId: number) => {
      const connection = new signalR.HubConnectionBuilder()
        .withUrl(OrderHubUrl(companyId).url)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      try {
        await connection
          .start()
          .then(() => {
            console.log("SignalR bağlantısı kuruldu.WaiterHub");
          })
          .catch((error) => {
            console.error(
              "SignalR bağlantısı başlatılırken hata oluştu:",
              error
            );
          });

        await connection.invoke(
          OrderHubEnum.orderClose,
          parseInt(companyId.toString()),
          order?.id,
          orderStatusId,
          order?.tableId
        );
      } catch (error) {
      } finally {
        connection.stop();
      }
    },
    [companyId, order]
  );

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Hesap
      </Button>
      <Drawer
        placement="bottom"
        title="Hesap İşlemleri"
        width={500}
        onClose={() => {
          setIsOpen(false);
        }}
        open={isOpen}
      >
        <Flex gap="small" wrap="wrap">
          {orderStatusModelList
            .filter((x) => x.text === OrderStatusEnum.paymentCompleted)
            .flatMap((x, i) => (
              <Button key={i}
                disabled={x.id === order?.statusId}
                style={{ borderColor: x.color, color: x.color }}
                onClick={() => closeOrder(x.id)}
              >
                {t(x.text.orderStatusLang())}
              </Button>
            ))}
        </Flex>
      </Drawer>
    </>
  );
};

export default CloseOrderDrawer;
