import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";

const AdminCompManagerHome = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      (deferredPrompt as any).prompt();
      (deferredPrompt as any).userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };
  return (
    <>
      <div
        style={{
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 30,
        }}
      >
        <img width={200} height={200} alt="" src="/gifs/settings.gif" />
        <Title>Yapım aşamasında... </Title>
        <br />
        {deferredPrompt && (
          <button onClick={handleInstallClick}>Uygulamayı Yükle</button>
        )}
      </div>
    </>
  );
};

export default AdminCompManagerHome;
