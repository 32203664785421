import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../configure_store";
import { BasketModel } from "../../../models/baskets/basket.model";

interface BasketState {
  basketList: BasketModel[];
}

const initialState: BasketState = {
  basketList: [],
};

const basketSlice = createSlice({
  name: "basket",
  initialState,
  reducers: {
    addBasket: (state, action: PayloadAction<BasketModel>) => {
      let basketList = state.basketList;
      let menuProductIndex = state.basketList.findIndex(
        (x) => x.menuProductId === action.payload.menuProductId
      );
      if (menuProductIndex !== -1) {
        basketList[menuProductIndex].amount += action.payload.amount;
        state.basketList = basketList;
      } else {
        state.basketList = [...state.basketList, action.payload];
      }
    },
    updateBasket: (state, action: PayloadAction<BasketModel>) => {
      let basketList = state.basketList;
      let menuProductIndex = state.basketList.findIndex(
        (x) => x.menuProductId === action.payload.menuProductId
      );
      if (menuProductIndex !== -1) {
        basketList[menuProductIndex] = action.payload;
        state.basketList = basketList;
      }
    },
    removeBasket: (state, action: PayloadAction<{ id: number }>) => {
      let basketList = state.basketList.filter(
        (x) => x.menuProductId !== action.payload.id
      );
      state.basketList = basketList ?? [];
    },
    clear: (state, action: PayloadAction) => {
      state.basketList = [];
    },
  },
});

export const { addBasket, removeBasket, updateBasket, clear } =
  basketSlice.actions;

export const selectBasketList = (state: RootState) => state.basket.basketList;

const BasketReducer = basketSlice.reducer;
export default BasketReducer;
