import dayjs from "dayjs";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PrinterModel } from "../../../services/printers/models/printer.model";
import {
  Button,
  Col,
  Input,
  Modal,
  Popconfirm,
  Row,
  Table,
  TableColumnsType,
} from "antd";
import CompanyMngrPrinterAdd from "./CompanyMngrPrinterAdd";
import { PrinterService } from "../../../services/printers/printer.service";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import Title from "antd/es/typography/Title";
import { SearchOutlined } from "@ant-design/icons";

const CompanyMngrPrinterList: React.FC = () => {
  const _printerService = useMemo(() => new PrinterService(), []);
  const companyId = useSelector(getCompanyId);
  const tempPrintList = useRef<PrinterModel[]>([]);
  const id = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState<PrinterModel[]>([]);
  const [count, setCount] = useState(0);
  const columns: TableColumnsType<PrinterModel> = [
    {
      title: "Adı",
      dataIndex: "name",
    },
    {
      title: "İp Adresi",
      dataIndex: "printerIpAddress",
    },
    {
      title: "Port",
      dataIndex: "printerPort",
    },

    {
      title: "İşlem Zamanı",

      dataIndex: "createdDate",
      render: (value, data) => {
        if (data.updatedDate && data.updatedDate !== null) {
          return (
            <div>{dayjs(data.updatedDate).format("DD/MM/YYYY HH:mm")}</div>
          );
        } else {
          return <div>{dayjs(value).format("DD/MM/YYYY HH:mm")}</div>;
        }
      },
    },

    {
      width: 50,
      title: "",
      dataIndex: "id",
      render: (value) => {
        return (
          <Button
            loading={isLoading}
            disabled={isLoading}
            type="text"
            onClick={() => {
              id.current = value;
              setModalVisible(true);
            }}
            style={{ backgroundColor: "#C4F3F7B2" }}
            icon={
              <i
                style={{ color: "green", fontSize: "large" }}
                className="fa-duotone fa-pen-to-square"
              ></i>
            }
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      width: 70,
      render(value, record) {
        return (
          <Popconfirm
            placement="left"
            title={"Kayıdı silinecek onaylıyormusunuz ?"}
            okText="Evet"
            cancelText="Hayır"
            onConfirm={async () => {
              setIsLoading(true);
              await _printerService.deleteById(value);
              await getPrinterList();
              setIsLoading(false);
            }}
          >
            <Button
              loading={isLoading}
              disabled={isLoading}
              danger
              icon={<i className="fa-duotone fa-trash"></i>}
            />
          </Popconfirm>
        );
      },
    },
  ];
  const onChangeSearch = useCallback((e: string) => {
    if (e.length > 0) {
      setData(
        tempPrintList.current.filter(
          (x) =>
            x.name.toLocaleLowerCase().includes(e.toLocaleLowerCase()) ||
            x.printerIpAddress
              ?.toLocaleLowerCase()
              .includes(e.toLocaleLowerCase())
        )
      );
    } else {
      setData(tempPrintList.current);
    }
  }, []);

  const getPrinterList = useCallback(async () => {
    const response = await _printerService.getList(companyId);
    if (response) {
      setData(response);
      tempPrintList.current=response;
    }
  }, []);
  const initialLoad = useCallback(() => {
    getPrinterList();
  }, []);
  useEffect(() => {
    if (companyId) {
      setCount((cou) => {
        if (cou === 0) {
          initialLoad();
        }
        return 1;
      });
    }
    return () => {};
  }, [initialLoad, companyId]);

  return (
    <div>
      <Title level={3} className="my-title">
        Yazıcı Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <Button
            loading={isLoading}
            onClick={() => {
              id.current = 0;
              setModalVisible(true);
            }}
            size="small"
            icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
          >
            Yeni Yazıcı Ekle
          </Button>
        </Col>
        <Col>
          <Input
            size="small"
            addonAfter={<SearchOutlined />}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
          />
        </Col>
      </Row>

      <Table
        size="small"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        rowKey={"id"}
        virtual={true}
        scroll={{
          y: "70vh",
        }}
      />
      {modalVisible ? (
        <Modal
          onCancel={async () => {
            id.current = 0;
            setModalVisible(false);
            setIsLoading(true);
            await getPrinterList();
            setIsLoading(false);
          }}
          footer={<></>}
          open={modalVisible}
        >
          <CompanyMngrPrinterAdd id={id.current} />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompanyMngrPrinterList;
