import { Button } from "antd";
import React, { useEffect, useState } from "react";

const AppInstall = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      (deferredPrompt as any).prompt();
      (deferredPrompt as any).userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };
  return (
    <div>
      {deferredPrompt && (
        <Button icon={<i className="fa-duotone fa-solid fa-download"></i>} onClick={handleInstallClick}>Uygulama Olarak Yükle</Button>
      )}
    </div>
  );
};

export default AppInstall;
