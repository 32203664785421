import BaseApiService from "../base_api.service";
import { CompanyModel } from "./models/company.model";

/**
 * Firma
 */
export class CompanyService extends BaseApiService {
  /**
   *
   */
  constructor() {
    super("Companies");
  }
  async getCompanyByTableId(
    tableId: string
  ): Promise<CompanyModel | undefined> {
    return await this.get<CompanyModel>(
      `/GetCompanyByTableId?tableId=${tableId}`
    );
  }
  getByCompanyId(id: number): Promise<CompanyModel | undefined> {
    return this.get<CompanyModel>(`${id}`);
  }
  async getList(): Promise<CompanyModel[]> {
    let response = await this.get<CompanyModel[]>(``);
    return response ?? [];
  }
  add(companyModel:CompanyModel):Promise<CompanyModel | undefined>{
    return this.post<CompanyModel>(``,companyModel);
  }
  update(companyModel:CompanyModel):Promise<CompanyModel|undefined>{
    return this.put<CompanyModel>(``,companyModel);
  }
  upadateByCompanyManager(companyModel:CompanyModel):Promise<CompanyModel|undefined>{
    return this.put<CompanyModel>(`UpadateByCompanyManager`,companyModel);
  }
}
