import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./template3.scss";
import { useSelector } from "react-redux";
import { getCompany } from "../../../../react-reduxs/slices/companies/company_slice";

import { OrderService } from "../../../../services/orders/order.service";
import Template3CategoryList from "./components/Template3CategoryList";
import MenuCategoryService from "../../../../services/menu_categories/menu_category.service";
import MenuProductServcie from "../../../../services/menu_products/menu_product.service";
import { MenuCategoryModel } from "../../../../services/menu_categories/models/menu_category.model";
import { MenuProductModel } from "../../../../services/menu_products/models/menu_product.model";
import Template3ProductList from "./components/Template3ProductList";
import { Col, Image, Row } from "antd";
import { ApiUrl } from "../../../../consts/api";
const Template3 = () => {
  const categoryService = useMemo(() => new MenuCategoryService(), []);
  const productService = useMemo(() => new MenuProductServcie(), []);
  // const orderService = useMemo(() => new OrderService(), []);

  const company = useSelector(getCompany);
  const [tooltipVisble, setTooltipVisble] = useState<boolean>(false);
  // const [floatVisible, setFloatVisible] = useState<boolean>(true);
  const [selecetedCategory, setSelecetedCategory] = useState<number>(0);
  const [categoryList, setCategoryList] = useState<MenuCategoryModel[]>([]);
  const menuProductListRef = useRef<MenuProductModel[]>([]);
  const [menuProductList, setMenuProductList] = useState<MenuProductModel[]>(
    []
  );
  const [count, setCount] = useState<number>(0);

  const getCategory = useCallback(async () => {
    let response = await categoryService.getListByStatus(company?.id ?? 0);
    if (response) {
      setCategoryList(response);
    }
  }, [categoryService, company?.id]);
  const getProduct = useCallback(async () => {
    let response = await productService.getListByStatus(company?.id ?? 0);
    if (response) {
      menuProductListRef.current = response;
      setMenuProductList(response);
    }
  }, [company?.id, productService]);

  const tooltipChange = useCallback((value: boolean) => {
    setTooltipVisble(value);
  }, []);

  const init = useCallback(async () => {
    await getCategory();
    await getProduct();
  }, [getCategory, getProduct]);

  const selectCategory = useCallback((id: number) => {
    setSelecetedCategory(id);
    if (id !== 0) {
      setMenuProductList(
        menuProductListRef.current.filter((x) => x.menuCategoryId === id) ?? []
      );
    }
    if (id === 0) {
      setMenuProductList(menuProductListRef.current);
    }
  }, []);

  useEffect(() => {
    setCount((cn) => {
      if (cn === 0) {
        init();
      }
      return 1;
    });

    setTimeout(() => {
      tooltipChange(true);
      setTimeout(() => {
        tooltipChange(false);
      }, 2000);
    }, 500);

    return () => {};
  }, [init, tooltipChange]);

  return (
    <div
      className="divApp"
      style={
        company?.backgroundImage
          ? {
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("${
                ApiUrl + "/" + company?.backgroundImage
              }")`,
            }
          : {
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("https://i.imgur.com/er8DtBW.jpg")`,
            }
      }
    >
      <div
        className="wrapper"
        style={{
          overflow: "auto",
          maxHeight: "100vh",
          minHeight: "100vh",
        }}
      >
        <Row style={{ height: 95 }}>
          <Col>
            <Image
              style={{ width: 70, height: 70, borderRadius: 10 }}
              src={ApiUrl + "/" + company?.logo ?? "../images/Backlink.png"}
            />
          </Col>
          <Col>
            <h2>{company?.title}</h2>
          </Col>
        </Row>

        <Template3CategoryList
          selectedCategory={selecetedCategory}
          categoryList={categoryList}
          onClick={selectCategory}
        />

        <Template3ProductList productList={menuProductList} />
      </div>
    </div>
  );
};

export default Template3;
