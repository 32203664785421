import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Progress, Upload } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import React, { useState } from "react";
import { FileUploadService } from "../../services/file_uploads/file_upload.service";
import { ApiUrl } from "../../consts/api";
import { toast } from "react-toastify";

interface FileUploadComponentFullProps {
  setImageUrlParam: React.Dispatch<React.SetStateAction<string>>;
  setDeleteImageParam?: React.Dispatch<React.SetStateAction<boolean>>;
  path: string;
}
const FileUploadComponentFull: React.FC<FileUploadComponentFullProps> = ({
  setImageUrlParam,
  path,
  setDeleteImageParam,
}) => {
  const fileUploadService = new FileUploadService();
  const [deletedImage, setDeletedImage] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState<number | undefined>(undefined);

  const handleUpload = async (options: any) => {
    const { file, onSuccess, onError } = options;
    const formData = new FormData();
    formData.append("file", file);
    setUploading(true);
    try {
      if (deletedImage) {
        await fileUploadService.deleteFile(imageUrl);
        setDeletedImage(false);
        if (setDeleteImageParam !== undefined) {
          setDeleteImageParam(false);
        }
      }
      let response = await fileUploadService.fileUpload(path, file);
      if (response) {
        onSuccess(response);
        setImageUrl(response.path);
        setImageUrlParam(response.path);
        setDeletedImage(true);
        if (setDeleteImageParam !== undefined) {
          setDeleteImageParam(true);
        }
        toast("Fotoğraf Yüklendi");
      }
    } catch (error) {
      onError(error);
      //  message.error('Failed to upload file');
    } finally {
      setUploading(false);
      setProgress(undefined);
    }
  };

  const onProgress = (progressInfo: { percent: number }) => {
    setProgress(progressInfo.percent);
  };

  return (
    <div>
      <Badge
        count={
          <Button disabled={imageUrl===""}
            onClick={async () => {
              await fileUploadService.deleteFile(imageUrl);
              setImageUrl("");
              setImageUrlParam("");
              setDeletedImage(false);
              if (setDeleteImageParam !== undefined) {
                setDeleteImageParam(false);
              }
            }}
            danger
            icon={<i className="fa-duotone fa-trash"></i>}
            size="small"
          />
        }
      >
        <Upload
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          customRequest={handleUpload}
          showUploadList={false}
          onChange={(info: UploadChangeParam<any>) => {
            if (info.file.status === "uploading") {
              onProgress({ percent: info.file.percent || 0 });
            }
          }}
        >
          {imageUrl ? (
            <img
              src={ApiUrl + "/" + imageUrl}
              alt="avatar"
              style={{ width: 100, height: 100, objectFit: "cover" }}
            />
          ) : (
            <>
              <Button
                style={{ border: 0, background: "none" }}
                htmlType="button"
              >
                {uploading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
              </Button>
            </>
          )}
        </Upload>
      </Badge>
      {uploading && <Progress percent={progress} />}
    </div>
  );
};

export default FileUploadComponentFull;
