import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  categoryIdChange,
  getProductList,
  getProductSelectedCategoryId,
} from "../../../../react-reduxs/slices/products/product_slice";
import { convertToUnicode } from "../../../../funcs/func";
import { getCompany } from "../../../../react-reduxs/slices/companies/company_slice";
import T4BasketAddBtnComponent from "./components/T4BasketAddBtnComponent";
import T4OrderAddBtnComponent from "./components/T4OrderAddBtnComponent";
import { ApiUrl } from "../../../../consts/api";
import { Button } from "antd";
import { MenuProductAmountType } from "../../../../models/menu_product_amount_type";
import { getTableId } from "../../../../react-reduxs/slices/tables/tableSlice";
import CounterComponent from "../../../../theme/ui-components/counter/CounterComponent";

const Template4ProductList = () => {
  const dispatch = useDispatch();
  const selectCategoryId = useSelector(getProductSelectedCategoryId);
  const productList = useSelector(getProductList);
  const company = useSelector(getCompany);
  const tableId = useSelector(getTableId);
  const [menuProductAmountListState, setMenuProductAmountListState] = useState<
    MenuProductAmountType[]
  >([]);
  const increment = useCallback((menuProductId: number) => {
    setMenuProductAmountListState((prev) => {
      const updatedList = prev.map((element) =>
        element.menuProductId === menuProductId
          ? { ...element, amount: element.amount + 1 }
          : element
      );
      if (
        !updatedList.some((element) => element.menuProductId === menuProductId)
      ) {
        updatedList.push({ menuProductId, amount: 2 });
      }
      return updatedList;
    });
  }, []);

  const descrement = useCallback((menuProductId: number) => {
    setMenuProductAmountListState((prev) => {
      const updatedList = prev.map((element) =>
        element.menuProductId === menuProductId && element.amount > 1
          ? { ...element, amount: element.amount - 1 }
          : element
      );
      if (
        !updatedList.some((element) => element.menuProductId === menuProductId)
      ) {
        updatedList.push({ menuProductId, amount: 2 });
      }
      return updatedList;
    });
  }, []);

  const removeMenuProductAmount = useCallback((menuProductId: number) => {
    setMenuProductAmountListState((prev) =>
      prev.filter((x) => x.menuProductId !== menuProductId)
    );
  }, []);

  return (
    <div style={{ paddingTop: 100,maxHeight:"100vh",overflow:"auto" }}>
      <Button
        className="t4-back-button"
        onClick={() => {
          dispatch(categoryIdChange(0));
        }}
        size="small"
        icon={<i className="fa-duotone fa-solid fa-arrow-left-long"></i>}
      >
        Kategori Listesi
      </Button>
      <div className="t4-product">
        {productList
          .filter((x) => x.menuCategoryId === selectCategoryId)
          .map((product) => {
            const count =
              menuProductAmountListState.find(
                (x) => x.menuProductId === product.id
              )?.amount ?? 1;
            return (
              <div className="t4-product-card" key={product.id}>
                <div className="t4-product-card-header">
                  <p className="t4-product-card-title">{product.title}</p>
                  <p className="t4-product-card-price">
                    {product.price}
                    {convertToUnicode(company?.currency?.symbol ?? "")}
                  </p>
                </div>
                <div className="t4-product-card-body">
                  <img
                    alt=""
                    src={
                      product.photo
                        ? `${ApiUrl}/${product.photo}`
                        : "/images/product.png"
                    }
                  />

                  <div className="t4-product-card-counter">
                    {company?.orderAtTheTable === true ? (
                      <CounterComponent
                        color="#008D63FF"
                        count={count}
                        increment={() => increment(product.id)}
                        descrement={() => descrement(product.id)}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {company?.orderAtTheTable === true ? (
                  <div className="t4-product-card-footer">
                    <T4BasketAddBtnComponent
                      menuProduct={product}
                      menuProductAmountList={menuProductAmountListState}
                      removeMenuProductAmount={removeMenuProductAmount}
                      tableId={tableId}
                    />
                    <T4OrderAddBtnComponent
                      menuProduct={product}
                      removeMenuProductAmount={removeMenuProductAmount}
                      menuProductAmountList={menuProductAmountListState}
                      tableId={tableId}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Template4ProductList;
