import React, { useCallback, useState } from "react";
import "../template-4.css";
import { FaShoppingCart } from "react-icons/fa";
import { MenuProductAmountType } from "../../../../../models/menu_product_amount_type";
import { MenuProductModel } from "../../../../../services/menu_products/models/menu_product.model";
import { BasketModel } from "../../../../../models/baskets/basket.model";
import { addBasket } from "../../../../../react-reduxs/slices/baskets/basker-slice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getCompany } from "../../../../../react-reduxs/slices/companies/company_slice";
import { AppDispatch } from "../../../../../react-reduxs/configure_store";
import { Input, message, Popconfirm } from "antd";
import { I18nPagePropertyEnum } from "../../../../../i18n/i18n_page_property";

interface T4BasketAddBtnComponentProps {
  menuProductAmountList: MenuProductAmountType[];
  menuProduct: MenuProductModel;
  tableId: string;
  removeMenuProductAmount: any;
  title?: string;
}
const T4BasketAddBtnComponent: React.FC<T4BasketAddBtnComponentProps> = ({
  tableId,
  menuProduct,
  menuProductAmountList,
  removeMenuProductAmount,
  title = "",
}) => {
  const company = useSelector(getCompany);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [orderNote, setOrderNote] = useState("");
  const [basketPopConfirmVisible, setBasketPopConfirmVisible] =
    useState<boolean>(false);

  const success = useCallback(
    (value: number) => {
      messageApi.open({
        type: "success",
        content: `${value} Ürün Sepete Eklendi. 😎`,
      });
    },
    [messageApi]
  );

  const basketAdd = useCallback(
    (menuProduct: MenuProductModel) => {
      let amount =
        menuProductAmountList.find((x) => x.menuProductId === menuProduct.id)
          ?.amount ?? 1;

      dispatch(
        addBasket({
          id: 0,
          tableId: tableId,
          menuProductId: menuProduct.id,
          menuProductName: menuProduct.title,
          menuProductPhoto: menuProduct.photo,
          price: menuProduct.price,
          amount: amount,
          orderNote: orderNote,
        } as BasketModel)
      );
      success(amount);
      removeMenuProductAmount(menuProduct.id);
    },
    [
      menuProductAmountList,
      dispatch,
      tableId,
      orderNote,
      success,
      removeMenuProductAmount,
    ]
  );
  return (
    <div>
      {contextHolder}
      {company?.orderAtTheTable ? (
        <button className="btn"   onClick={() => setBasketPopConfirmVisible(true)}>
          <FaShoppingCart /> Sepet Ekle
        </button>
      ) : (
        <></>
      )}
      <Popconfirm
        placement="rightTop"
        cancelText={t(I18nPagePropertyEnum.commonCancel)}
        okText={t(I18nPagePropertyEnum.commonOk)}
        onCancel={() => {
          setBasketPopConfirmVisible(false);
          setOrderNote("");
        }}
        icon={
          <i
            style={{ color: "green", marginRight: 5 }}
            className="fa-duotone fa-cart-plus"
          ></i>
        }
        title={t(I18nPagePropertyEnum.menuProductAddToBasket)}
        description={
          <>
            <label>{t(I18nPagePropertyEnum.menuProductOrderNote)}</label>
            <Input.TextArea
              value={orderNote}
              onChange={(e) => setOrderNote(e.target.value)}
            />
          </>
        }
        open={basketPopConfirmVisible}
        onConfirm={async () => {
          basketAdd(menuProduct);
          setBasketPopConfirmVisible(false);
        }}
      ></Popconfirm>
    </div>
  );
};

export default T4BasketAddBtnComponent;
