import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Provider, useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { TableModel } from "../../../services/tables/models/tabele.model";
import {
  Button,
  Col,
  Input,
  List,
  Modal,
  Popconfirm,
  QRCode,
  Row,
  Table,
  TableColumnsType,
} from "antd";
import Title from "antd/es/typography/Title";
import { TableService } from "../../../services/tables/table.service";
import CompManagerTableAdd from "./CompManagerTableAdd";
import { SectionService } from "../../../services/sections/serction.service";
import { SectionModel } from "../../../services/sections/models/section.model";
import { toast } from "react-toastify";
import { ApiUrl } from "../../../consts/api";
import { store } from "../../../react-reduxs/configure_store";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";
import { SearchOutlined } from "@ant-design/icons";

const CompManagerTableList = () => {
  const companyId = useSelector(getCompanyId);
  const tableService = useMemo(() => new TableService(), []);
  const sectionService = useMemo(() => new SectionService(), []);
  const fileUploadService = new FileUploadService();

  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [openModalCompanyAdd, setOpenModalCompanyAdd] =
    useState<boolean>(false);
  const [data, setData] = useState<TableModel[]>([]);
  const [sectionList, setSectionList] = useState<SectionModel[]>([]);
  const tempTableList = useRef<TableModel[]>([]);
  /** const domainUrl = window.location.origin;*/ 
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const columns: TableColumnsType<TableModel> = [
    {
      width: 100,
      title: "Fotoğraf",
      dataIndex: "logo",

      render: (value) => {
        return (
          <img
            width={50}
            height={50}
            src={
              value !== undefined ? ApiUrl + "/" + value : "/images/table.png"
            }
            alt=""
          />
        );
      },
    },
    {
      title: "Adı",
      dataIndex: "name",
    },
    {
      title: "Masa Numarası",
      dataIndex: "number",
    },
    {
      title: "Kare kod oluştur",
      dataIndex: "id",
      render: (value) => {
        return (
          <Button 
            onClick={() => {
              Modal.success({
                content: (
                  <QRCode
                    size={300}
                    errorLevel="H"
                    value={`https://menu.qrmenuscan.com/${value}`}
                    icon="/images/Backlink.png"
                  />
                ),
              });
            }}
            icon={<i className="fa-light fa-qrcode"></i>}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      width: 50,
      render: (value) => {
        return (
          <Button
            type="text"
            onClick={() => {
              Modal.info({
                okText: "İptal",
                okType: "danger",
                content: (
                  <Provider store={store}>
                    <CompManagerTableAdd
                      isLoading={isLoading}
                      getTableList={getTable}
                      tableService={tableService}
                      id={value}
                      add={addTable}
                      addSection={addSection}
                      sectionList={sectionList}
                    />
                  </Provider>
                ),
              });
            }}
            icon={
              <i
                style={{ color: "green", fontSize: "large" }}
                className="fa-duotone fa-pen-to-square"
              ></i>
            }
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      width: 70,
      render(value, record) {
        return (
          <Popconfirm
            placement="left"
            title={"Kayıdı silinecek onaylıyormusunuz ?"}
            okText="Evet"
            cancelText="Hayır"
            onConfirm={async () => {
              setIsLoading(true);
              var response = await tableService.deleteById(value);
              if (response) {
                if (record.logo !== undefined && record.logo !== "") {
                  await fileUploadService.deleteFile(record.logo);
                }
                toast.success("Silme başarılı");
              }
              await getTable();
              setIsLoading(false);
            }}
          >
            <Button
              loading={isLoading}
              disabled={isLoading}
              danger
              icon={<i className="fa-duotone fa-trash"></i>}
            />
          </Popconfirm>
        );
      },
    },
  ];

  const getSection = useCallback(async () => {
    let response = await sectionService.getListByCompanyId(companyId);
    if (response) {
      setSectionList(response);
    }
  }, [companyId, sectionService]);

  const getTable = useCallback(async () => {
    let response = await tableService.getListByCompanyId(companyId);
    if (response) {
      setData(response);
      tempTableList.current = response;
    }
  }, [companyId, tableService]);
  const addSection = useCallback(
    async (sectionModel: SectionModel) => {
      setIsLoading(true);
      if (sectionModel) {
        let response = await sectionService.add(sectionModel);
        if (response) {
          toast.success("Kayıt işlemi başarılı");
          await getSection();
        }
      }
      setIsLoading(false);
    },
    [getSection, sectionService]
  );
  const addTable = useCallback(
    async (tableModel: TableModel) => {
      setIsLoading(true);
      if (tableModel.id === "") {
        let response = await tableService.add(tableModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getTable();
        }
      } else {
        let response = await tableService.update(tableModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getTable();
        }
      }
      setIsLoading(false);
    },
    [getTable, tableService]
  );

  const initLoad = useCallback(async () => {
    setIsLoading(true);
    await getSection();
    await getTable();
    setIsLoading(false);
  }, [getSection, getTable]);

  useEffect(() => {
    if (companyId) {
      setCount((cou) => {
        if (cou === 0) {
          initLoad();
        }
        return 1;
      });
    }
    return () => {};
  }, [companyId, initLoad]);
  const onChangeSearch = useCallback((e: string) => {
    if (e.length > 0) {
      setData(
        tempTableList.current.filter((x) =>
          x.name.toLocaleLowerCase().includes(e.toLocaleLowerCase())
        )
      );
    } else {
      setData(tempTableList.current);
    }
  }, []);
  return (
    <div>
      <Title level={3} className="my-title">
        Masa Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <Button
            loading={isLoading}
            onClick={() => {
              setOpenModalCompanyAdd(true);
            }}
            size="small"
            icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
          >
            Yeni Masa Ekle
          </Button>
        </Col>
        <Col>
          <Input
            size="small"
            addonAfter={<SearchOutlined />}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      {windowSize.width > 605 ? (
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data}
          rowKey={"id"}
          virtual={true}
          style={{ maxHeight: "70vh", overflow: "auto" }}
        />
      ) : (
        <List
          itemLayout="vertical"
          size="small"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 3,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              key={item.name}
              actions={[
                <Button
                  key={1}
                  onClick={() => {
                    Modal.success({
                      content: (
                        <QRCode
                          size={300}
                          errorLevel="H"
                          value={`https://menu.qrmenuscan.com/${item.id}`}
                          icon="/images/Backlink.png"
                        />
                      ),
                    });
                  }}
                  icon={<i className="fa-light fa-qrcode"></i>}
                />,
                <Button
                  key={2}
                  type="text"
                  onClick={() => {
                    Modal.info({
                      okText: "İptal",
                      okType: "danger",
                      content: (
                        <Provider store={store}>
                          <CompManagerTableAdd
                            isLoading={isLoading}
                            getTableList={getTable}
                            tableService={tableService}
                            id={item.id}
                            add={addTable}
                            addSection={addSection}
                            sectionList={sectionList}
                          />
                        </Provider>
                      ),
                    });
                  }}
                  icon={
                    <i
                      style={{ color: "green", fontSize: "large" }}
                      className="fa-duotone fa-pen-to-square"
                    ></i>
                  }
                />,
                <Popconfirm
                  key={3}
                  placement="left"
                  title={"Kayıdı silinecek onaylıyormusunuz ?"}
                  okText="Evet"
                  cancelText="Hayır"
                  onConfirm={async () => {
                    setIsLoading(true);
                    let response = await tableService.deleteById(item.id);
                    if (response) {
                      if (item.logo !== undefined && item.logo !== "") {
                        await fileUploadService.deleteFile(item.logo);
                      }
                      toast.success("Silme başarılı");
                    }
                    await getTable();
                    setIsLoading(false);
                  }}
                >
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    danger
                    icon={<i className="fa-duotone fa-trash"></i>}
                  />
                </Popconfirm>,
              ]}
              extra={
                <img
                  className="ant-list-item-img"
                  alt="logo"
                  src={
                    item.logo
                      ? ApiUrl + `/` + "/" + item.logo
                      : "/images/product.png"
                  }
                />
              }
            >
              <List.Item.Meta title={item.name} />
            </List.Item>
          )}
        />
      )}

      {openModalCompanyAdd ? (
        <Modal
          title=""
          open={openModalCompanyAdd}
          okText={undefined}
          cancelText={undefined}
          onCancel={() => setOpenModalCompanyAdd(false)}
          styles={{ mask: { backdropFilter: "blur(10px)" } }}
          footer={<></>}
        >
          <CompManagerTableAdd
            isLoading={isLoading}
            tableService={tableService}
            add={addTable}
            addSection={addSection}
            sectionList={sectionList}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompManagerTableList;
