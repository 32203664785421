import { Button, Col, Form, Input, Row, Select, Space, Switch } from "antd";
import Title from "antd/es/typography/Title";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GroupModel } from "../../../services/groups/models/group.model";
import { GroupService } from "../../../services/groups/group.service";
import { UserService } from "../../../services/users/user.service";
import { toast } from "react-toastify";

interface AdminUserAddProps {
  id: number;
}
const AdminUserAdd: React.FC<AdminUserAddProps> = ({ id }) => {
  const [form] = Form.useForm();
  const [groupList, setGroupList] = useState<GroupModel[]>([]);
  const groupService = useMemo(() => new GroupService(), []);
  const userService = useMemo(() => new UserService(), []);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getUser = useCallback(async () => {
    let response = await userService.getById(id);
    if (response) {
      form.setFieldsValue({
        ...response,
      });
    }
  }, [userService]);
  const getGroupList = useCallback(async () => {
    let response = await groupService.list();
    if (response) {
      setGroupList(response);
    }
  }, [groupService]);

  const initLoad = useCallback(async () => {
    setIsLoading(true);
    await getUser();
    await getGroupList();
    setIsLoading(false);
  }, [getGroupList]);

  useEffect(() => {
    console.error(id);

    setCount((cou) => {
      if (cou === 0) {
        initLoad();
      }
      return 1;
    });
    return () => {};
  }, [initLoad]);

  const onFinish = async (value: any) => {
    setIsLoading(true);
    let response = await userService.updateUser(value);
    if (response) {
      toast.success("Kayıt Başarılı");
    }
    setIsLoading(false);
  };

  const onFinishFailed = () => {};

  return (
    <div>
      <Title level={3}>Kullanıcı Ekle</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col flex={1}>
            <Form.Item name="default" label="Varsayılan" initialValue={false}>
              <Switch loading={isLoading} disabled={isLoading} checkedChildren="Aktif" unCheckedChildren="Pasif" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="id" initialValue={0} rules={[{ required: true }]}>
              <Input placeholder="" disabled type="hidden" />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ gap: 3 }}>
          <Col flex={1}>
            <Form.Item
              name="userName"
              label="Kullanıcı Adı"
              rules={[{ required: true }]}
            >
              <Input placeholder="Kullanıcı Adı" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="email"
              label="E-Posta"
              rules={[{ required: true }]}
            >
              <Input placeholder="E-Posta" />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              name="groupId"
              label="Kullanıcı Grubu"
              rules={[{ required: true }]}
            >
              <Select
                loading={isLoading}
                disabled={isLoading}
                style={{ width: "100%" }}
                options={groupList.map((item) => ({
                  value: item.id,
                  label: item.groupName,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button
              loading={isLoading}
              disabled={isLoading}
              type="primary"
              htmlType="submit"
            >
              Kaydet
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminUserAdd;
