import BaseApiService from "../base_api.service";
import { AddUserCompanyModel } from "./models/add_user_company.model";
import { UpdateUserPassowrdModel } from "./models/update_user_pasword.model";
import { UserModel } from "./models/user.model";
import { UserListModel } from "./models/user_list.model";
import { PasswordUpdateModel } from "./models/password_update.model";

export class UserService extends BaseApiService {
  constructor() {
    super("Users");
  }

  addUserCompany(
    addUserCompanyModel: AddUserCompanyModel
  ): Promise<UserModel | undefined> {
    return this.post(``, addUserCompanyModel);
  }
  updateUser(
    addUserCompanyModel: AddUserCompanyModel
  ): Promise<UserModel | undefined> {
    return this.put(``, addUserCompanyModel);
  }
  async list(): Promise<UserListModel[]> {
    let response = await this.get<UserListModel[]>(``);
    return response ?? [];
  }
  async getListByCompanyId(companyId: number): Promise<UserListModel[]> {
    let response = await this.get<UserListModel[]>(
      `getListByCompanyId?companyId=${companyId}`
    );
    return response ?? [];
  }
  getById(id: number): Promise<UserListModel | undefined> {
    return this.get<UserListModel>(`GetById?id=${id}`);
  }
  passwordReset(model: UpdateUserPassowrdModel): Promise<string | undefined> {
    return this.put<string>(`/passwordReset`, model);
  }
  myPasswordReset(
    passwordUpdateModel: PasswordUpdateModel
  ): Promise<string | undefined> {
    return this.put<string>(`/myPasswordReset`, passwordUpdateModel);
  }
  disableUser(
    id: number,
    date?: string | null
  ): Promise<UserModel | undefined> {
    console.error(date);

    return this.put<UserModel>(`/userDeletedDateUpdate`, {
      id: id,
      deletedDate: date,
    });
  }
  deleteUser(id: number): Promise<string | undefined> {
    return this.delete<string>(`?id=${id}`);
  }
}
