import React from "react";
import {
  classNames,
  defaultPosition,
} from "./funcs/horizantel_scrollable.func";
import {
  IScrollableComponentProps,
  IScrollableComponentState,
} from "./interfaces/horizantel_scrollable.interface";
import { Button } from "antd";

const HorizantelScrollable = (props: IScrollableComponentProps) => {
  const ref: React.MutableRefObject<HTMLDivElement | null> =
    React.useRef<HTMLDivElement>(null);
  const scrollToLeft = () => {
    if (ref.current) {
      ref.current.scrollLeft -= 100; // Belirli bir oranda sola kaydırma (örneğin 100 piksel)
    }
  };
  const scrollToRight = () => {
    if (ref.current) {
      ref.current.scrollLeft += 100; // Belirli bir oranda sola kaydırma (örneğin 100 piksel)
    }
  };
  const [state, setStateTo] = React.useState<IScrollableComponentState>({
    grabbing: false,
    position: defaultPosition(),
  });

  const handleOnMouseDown = (e: any): void => {
    if (ref.current) {
      setStateTo({
        ...state,
        grabbing: true,
        position: {
          x: e.clientX,
          left: ref.current.scrollLeft,
        },
      });
    }
  };

  const handleOnMouseMove = (e: any): void => {
    if (state.grabbing) {
      const left: number = Math.max(
        0,
        state.position.left + (state.position.x - e.clientX)
      );

      if (ref.current) {
        ref.current.scrollLeft = left;
      }
    }
  };

  const handleOnMouseUp = (): void => {
    if (state.grabbing) {
      setStateTo({ ...state, grabbing: false });
    }
  };
  return (
    <div
      ref={ref}
      className={classNames("scrollable-component", props.className)}
      id={props.id}
      style={props.style}
      onMouseDown={handleOnMouseDown}
      onMouseMove={handleOnMouseMove}
      onMouseUp={handleOnMouseUp}
      onMouseLeave={handleOnMouseUp}
    >
      <Button
        onClick={() => scrollToLeft()}
        style={{
          position: "fixed",
          zIndex: 10000000000,
          backgroundColor: "#ffffff87",
          height: 65,
          left:0,
          margin:10
        }}
        type="text"
        size="middle"
        icon={
          <i style={{ fontSize: 25 }} className="fa-solid fa-chevron-left "></i>
        }
      />
      {props.children}
      <Button
        onClick={() => scrollToRight()}
        style={{
          position: "fixed",
          zIndex: 10000000000,
          right: 0,
          backgroundColor: "#ffffff87",
          height: 65,
          margin:10
        }}
        type="text"
        size="middle"
        icon={
          <i
            style={{ fontSize: 25 }}
            className="fa-solid fa-chevron-right "
          ></i>
        }
      />
    </div>
  );
};

export default HorizantelScrollable;
