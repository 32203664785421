import BaseApiService from "../base_api.service";
import { PrinterModel } from "./models/printer.model";

export class PrinterService extends BaseApiService {
  constructor() {
    super("Printers");
  }

  async getList(companyId: number): Promise<PrinterModel[]> {
    return (await this.get<PrinterModel[]>(`?companyId=${companyId}`)) ?? [];
  }
  getById(id: number): Promise<PrinterModel | undefined> {
    return this.get<PrinterModel>(`GetById?id=${id}`);
  }
  add(printerModel: PrinterModel): Promise<PrinterModel | undefined> {
    return this.post<PrinterModel>(``, printerModel);
  }
  update(printerModel: PrinterModel): Promise<PrinterModel | undefined> {
    return this.put<PrinterModel>(``, printerModel);
  }
  deleteById(id: number): Promise<string | undefined> {
    return this.delete<string>(`?id=${id}`);
  }
}
