import { I18nPageProperty } from "../i18n_page_property";

const Tr = {
  waiterLayout: {
    order: I18nPageProperty.waiterLayout.order.tr,
  },
  common: {
    pendingApproval: I18nPageProperty.common.pendingApproval.tr,
    approved: I18nPageProperty.common.approved.tr,
    itIsCancelled: I18nPageProperty.common.itIsCancelled.tr,
    orderIsBeingPrepared: I18nPageProperty.common.orderIsBeingPrepared.tr,
    ok: I18nPageProperty.common.ok.tr,
    cancel: I18nPageProperty.common.cancel.tr,
    orders: I18nPageProperty.common.orders.tr,
    amount: I18nPageProperty.common.amount.tr,
    logOut: I18nPageProperty.common.logOut.tr,
    sendOrder: I18nPageProperty.common.sendOrder.tr,
    paymentNotMade:I18nPageProperty.common.paymentNotMade.tr,
    paymentCompleted:I18nPageProperty.common.paymentCompleted.tr,
  },
  waiter: {
    confirmAllOrders: I18nPageProperty.waiter.confirmAllOrders.tr,
    tableDetailTitle: I18nPageProperty.waiter.tableDetailTitle.tr,
    theWaiterIsCalling: I18nPageProperty.waiter.theWaiterIsCalling.tr,
  },
  login: {
    title: I18nPageProperty.login.title.tr,
    userName: I18nPageProperty.login.userName.tr,
    password: I18nPageProperty.login.password.tr,
    email: I18nPageProperty.login.email.tr,
    login: I18nPageProperty.login.login.tr,
    language: I18nPageProperty.login.language.tr,
  },
  menuProduct: {
    orderNote: I18nPageProperty.menuProduct.orderNote.tr,
    addToBasket: I18nPageProperty.menuProduct.addToBasket.tr,
    waiter: I18nPageProperty.menuProduct.waiter.tr,
    callTheWaiter: I18nPageProperty.menuProduct.callTheWaiter.tr,
  },
  template: {
    waiterFloat: I18nPageProperty.template.waiterFloat.tr,
    basketFloat: I18nPageProperty.template.basketFloat.tr,
    tableFloat: I18nPageProperty.template.tableFloat.tr,
    Waiter:I18nPageProperty.template.waiter.tr,
    Admin:I18nPageProperty.template.admin.tr,
    CompanyManager:I18nPageProperty.template.companyManager.tr
  },
};
export default Tr;
