import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PrinterModel } from "../../../services/printers/models/printer.model";
import { RootState } from "../../configure_store";

interface PrinterState {
  printerList: PrinterModel[];
}

const initialState: PrinterState = {
  printerList: [],
};
const printerSlice = createSlice({
  name: "printer",
  initialState,
  reducers: {
    setPrinterList: (state, action: PayloadAction<PrinterModel[]>) => {
      state.printerList = action.payload;
    },
  },
});

export const { setPrinterList } = printerSlice.actions;

export const getPrinterList = (state: RootState) => state.printer.printerList;

const PrinterReducer = printerSlice.reducer;
export default PrinterReducer;
