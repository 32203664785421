import { Button } from "antd";
import { connect } from "node:net";
import React, { useCallback } from "react";
// import escpos from "escpos";
import { Printer, render, Text } from "react-thermal-printer";

interface ButtonPrinterProps {
  ipAddress: string;
  port: number;
}
const ButtonPrinter: React.FC<ButtonPrinterProps> = ({ ipAddress, port }) => {
  const onPrint = useCallback(async () => {
    // const device = new escpos.Network(ipAddress, port); // Ağ yazıcısı için
    // const printer = new escpos.Printer(device);
    // console.error(printer);
    try {
      //   // Yazdırılacak veriyi oluştur
      //   const data = await render(
      //     <Printer type="epson">
      //       <Text>Hello World</Text>
      //     </Printer>
      //   );

      //   // Serial port istemcisini aç
      //   const port = await window.navigator.serial.requestPort();
      //   await port.open({ baudRate: 9600 });

      //   // Yazma işlemi için yazar al
      //   const writer = port.writable.getWriter();

      //   // Veriyi yazıcıya gönder
      //   await writer.write(data);
      //   console.log("Data sent to printer");

      //   // Yazma işlemini tamamla
      //   writer.releaseLock();
      const data = await render(
        <Printer type="epson">
          <Text>Hello World</Text>
        </Printer>
      );

      //   const conn = connect(
      //     {
      //       host: "192.168.0.99",
      //       port: 9100,
      //       timeout: 3000,
      //     },
      //     () => {
      //       conn.write(Buffer.from(data), () => {
      //         conn.destroy();
      //       });
      //     }
      //   );  
      try {
        const response2 = await fetch("https://" + ipAddress + ":" + port, {
          method: "POST",
          body: data, // Burada veri gönderilir
        });
        if (response2.ok) {
          console.log("https: Yazdırma işlemi başarılı.", response2);
        } else {
          console.error("https: Yazdırma işlemi başarısız:", response2);
        }
      } catch (error) {
        console.error("https:Error printing:", error);
      }
      const response = await fetch("http://" + ipAddress + ":" + port, {
        method: "POST",
        body: data, // Burada veri gönderilir
      });
    

      if (response.ok) {
        console.log("Yazdırma işlemi başarılı.", response);
      } else {
        console.error("Yazdırma işlemi başarısız:", response);
      }
    } catch (error) {
      console.error("Error printing:", error);
    }
  }, []);

  return (
    <div>
      <Button onClick={onPrint}>Yazdır</Button>
    </div>
  );
};

export default ButtonPrinter;
