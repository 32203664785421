import React, { useCallback, useEffect } from "react";
import UiDropdownList from "../../../theme/ui-components/dropdowns/UiDropdownList";
import { Avatar, Badge, Col, MenuProps, Row, Image } from "antd";
import { useSelector } from "react-redux";
import { selectOrder } from "../../../react-reduxs/slices/orders/order_slice";
import { ApiUrl } from "../../../consts/api";
import { OrderProductStatusEnum } from "../../../enums/orders/order_status.enum";
interface CompanyTableOrderPageProps {
  color?: string;
}
const CompanyTableOrderPage: React.FC<CompanyTableOrderPageProps> = ({
  color = "black",
}) => {
  const order = useSelector(selectOrder);

  const totalPrice = useCallback(() => {
    let locTotal = 0;
    if (order?.orderProductList) {
      order?.orderProductList.forEach((element) => {
        locTotal += element.amount * element.menuProductPrice;
      });
    }

    return locTotal;
  }, [order?.orderProductList]);
  useEffect(() => {
    return () => {};
  }, [order]);

  const items: MenuProps["items"] = [
    ...(order?.orderProductList ?? []).map((x) => {
      return {
        style: { width: "100%", minWidth: "100%", paddingTop: 10 },
        label: (
          <Badge
            styles={{
              indicator: { backgroundColor: x.status.color, right: 20 },
              root: { width: "-webkit-fill-available" },
            }}
            count={x.amount + " Adet"}
          >
            <Row
              style={{
                flexWrap: "nowrap",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",

                gap: 5,
              }}
            >
              <Col>
                <Image
                  src={ApiUrl + "/" + x.menuProductPhoto}
                  style={{ objectFit: "cover" }}
                  height={70}
                  width={70}
                />
              </Col>
              <Col
                flex={2}
                style={{
                  fontSize: 18,
                  paddingLeft: 10,
                  paddingTop: 20,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: "210px",
                  textOverflow: "ellipsis",
                  padding: 0,
                }}
              >
                {x.menuProductName}
                <h5>{x.menuProductPrice} ₺</h5>
              </Col>
            </Row>
          </Badge>
        ),
        key: x.menuProductId,
      };
    }),
  ];
  return (
    <Badge
      count={
        order !== undefined
          ? order?.orderProductList?.filter(
              (x) => x.status.text !== OrderProductStatusEnum.pendingApproval
            ).length
          : 0
      }
    >
      <UiDropdownList
        header={
          <Row>
            <Col span={20}>Adı</Col>
            <Col>Miktar</Col>
          </Row>
        }
        footer={
          <Row style={{}}>
            <Col flex={1} style={{ fontWeight: "bold" }}>
              Toplam Tutar: {totalPrice()} ₺
            </Col>
          </Row>
        }
        items={items}
        onClick={() => {}}
      >
        <Avatar
          style={{ backgroundColor: "white" }}
          shape="square"
          icon={
            <i
              style={{ color: color }}
              className="fa-solid fa-plate-utensils "
            ></i>
          }
        />
      </UiDropdownList>
    </Badge>
  );
};

export default CompanyTableOrderPage;
