export const Templates = [
  {
    id: 1,
    text: "Şablon 1",
  },
  // {
  //   id: 2,
  //   text: "Şablon 2 Beta",
  // },
  {
    id: 3,
    text: "Şablon 3",
  },
  {
    id: 4,
    text: "Şablon 4 Beta",
  },
];
