import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { getCompanyId } from "../../../react-reduxs/slices/auth/auth_slice";
import { MenuProductModel } from "../../../services/menu_products/models/menu_product.model";
import {
  Button,
  Col,
  Input,
  List,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Table,
  TableColumnsType,
} from "antd";
import Title from "antd/es/typography/Title";
import MenuProductServcie from "../../../services/menu_products/menu_product.service";
import dayjs from "dayjs";
import CompManagerMenuProductAdd from "./CompManagerMenuProductAdd";
import MenuCategoryService from "../../../services/menu_categories/menu_category.service";
import { MenuCategoryModel } from "../../../services/menu_categories/models/menu_category.model";
import { toast } from "react-toastify";
import { ApiUrl } from "../../../consts/api";
import { FileUploadService } from "../../../services/file_uploads/file_upload.service";

import "./product-list.css";
import { getCompany } from "../../../react-reduxs/slices/companies/company_slice";
import { convertToUnicode } from "../../../funcs/func";
import { SearchOutlined } from "@ant-design/icons";
const CompManagerMenuProductList: React.FC = () => {
  const id = useRef(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const tempProductList = useRef<MenuProductModel[]>([]);
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);

  const menuProductService = useMemo(() => new MenuProductServcie(), []);
  const menuCategoryService = useMemo(() => new MenuCategoryService(), []);
  const fileUploadService = useMemo(() => new FileUploadService(), []);
  const [paginateSize, setPaginateSize] = useState(10);
  const [count, setCount] = useState(0);
  const [data, setData] = useState<MenuProductModel[]>([]);
  const [categoryList, setCategoryList] = useState<MenuCategoryModel[]>([]);

  const getCategory = useCallback(async () => {
    let response = await menuCategoryService.getList(companyId);

    if (response) {
      setCategoryList(response);
    }
  }, [companyId, menuCategoryService]);

  const getProduct = useCallback(async () => {
    let response = await menuProductService.getList(companyId);
    if (response) {
      tempProductList.current = response;
      setData(response);
    }
  }, [companyId, menuProductService]);

  const initLoad = useCallback(async () => {
    setIsLoading(true);
    await getCategory();
    await getProduct();
    setIsLoading(false);
  }, [getCategory, getProduct]);

  useEffect(() => {
    if (companyId) {
      setCount((cou) => {
        if (cou === 0) {
          initLoad();
        }
        return 1;
      });
    }
    return () => {};
  }, [companyId, initLoad]);
  const onChangeSearch = useCallback((e: string) => {
    if (e.length > 0) {
      setData(
        tempProductList.current.filter(
          (x) =>
            x.title.toLocaleLowerCase().includes(e.toLocaleLowerCase()) ||
            x.detail?.toLocaleLowerCase().includes(e.toLocaleLowerCase())
        )
      );
    } else {
      setData(tempProductList.current);
    }
  }, []);

  const saveProduct = useCallback(
    async (menuProductModel: MenuProductModel) => {
      setIsLoading(true);
      console.error(menuProductModel);

      if (menuProductModel.id === 0 || menuProductModel.id === undefined) {
        let response = await menuProductService.add(menuProductModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getProduct();
        }
      } else {
        let response = await menuProductService.update(menuProductModel);
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getProduct();
        }
      }
      setIsLoading(false);
    },
    [getProduct, menuProductService]
  );
  const deleteMenuProductImage = useCallback(
    async (menuProductModel: MenuProductModel, imagePath: string) => {
      setIsLoading(true);
      let fileResponse = await fileUploadService.deleteFile(imagePath);
      if (fileResponse) {
        let response = await menuProductService.update({
          ...menuProductModel,
          photo: undefined,
        });
        if (response) {
          toast("Kayıt işlemi başarılı.");
          await getProduct();
          setIsLoading(false);
          return response;
        }
      }
      setIsLoading(false);
      return undefined;
    },
    [fileUploadService, getProduct, menuProductService]
  );
  const saveCategory = useCallback(
    async (menuCategoryModel: MenuCategoryModel) => {
      setIsLoading(true);
      let response = await menuCategoryService.add(menuCategoryModel);
      if (response) {
        toast("Kayıt işlemi başarılı.");

        await getCategory();
      } else if (
        menuCategoryModel.photo !== undefined &&
        menuCategoryModel.photo !== ""
      ) {
        fileUploadService.deleteFile(menuCategoryModel.photo);
      }
      setIsLoading(false);
    },
    [fileUploadService, getCategory, menuCategoryService]
  );

  const columns: TableColumnsType<MenuProductModel> = [
    {
      width: 100,
      title: "Fotoğraf",
      dataIndex: "photo",
      render: (value) => {
        return (
          <img
            width={70}
            height={70}
            src={value ? ApiUrl + "/" + value : "/images/product.png"}
            alt=""
          />
        );
      },
    },
    {
      title: "Başlık",
      dataIndex: "title",
    },
    {
      title: "Fiyatı",
      dataIndex: "price",
      width: 100,
    },
    {
      title: "Detay",
      dataIndex: "detail",
    },
    {
      title: "Durum",
      dataIndex: "status",
      width: 100,
      render: (value) => {
        return isLoading === false ? (
          <Switch
            checkedChildren="Aktif"
            disabled
            unCheckedChildren="Pasif"
            defaultChecked={value}
          />
        ) : (
          <></>
        );
      },
    },
    {
      title: "İşlem Zamanı",
      width: 200,
      dataIndex: "createdDate",
      render: (value, data) => {
        if (data.updatedDate && data.updatedDate !== null) {
          return (
            <div>{dayjs(data.updatedDate).format("DD/MM/YYYY HH:mm")}</div>
          );
        } else {
          return <div>{dayjs(value).format("DD/MM/YYYY HH:mm")}</div>;
        }
      },
    },

    {
      width: 50,
      title: "",
      dataIndex: "id",
      render: (value) => {
        return (
          <Button
            loading={isLoading}
            disabled={isLoading}
            type="text"
            onClick={() => {
              id.current = value;
              setModalVisible(true);
            }}
            style={{ backgroundColor: "#C4F3F7B2" }}
            icon={
              <i
                style={{ color: "green", fontSize: "large" }}
                className="fa-duotone fa-pen-to-square"
              ></i>
            }
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      width: 70,
      render(value, record) {
        return (
          <Popconfirm
            placement="left"
            title={"Kayıdı silinecek onaylıyormusunuz ?"}
            okText="Evet"
            cancelText="Hayır"
            onConfirm={async () => {
              setIsLoading(true);
              let response = await menuProductService.deleteProduct(value);
              if (response) {
                if (record.photo !== undefined && record.photo !== "") {
                  await fileUploadService.deleteFile(record.photo);
                }
                toast.success("Silme başarılı");
              }
              await getProduct();
              setIsLoading(false);
            }}
          >
            <Button
              loading={isLoading}
              disabled={isLoading}
              danger
              icon={<i className="fa-duotone fa-trash"></i>}
            />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div>
      <Title level={3} className="my-title">
        Ürün Listesi
      </Title>
      <Row className="row-header">
        <Col>
          <Button
            loading={isLoading}
            onClick={() => {
              id.current = 0;
              setModalVisible(true);
            }}
            size="small"
            icon={<i className="fa-solid fa-rectangle-history-circle-plus"></i>}
          >
            Yeni Ürün Ekle
          </Button>
        </Col>
        <Col>
          <Input
            size="small"
            addonAfter={<SearchOutlined />}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
          />
        </Col>
      </Row>

      {windowSize.width > 605 ? (
        <Table
          size="small"
          loading={isLoading}
          columns={columns}
          dataSource={data}
          rowKey={"id"}
          pagination={{
            pageSize: paginateSize,
            onShowSizeChange(current, size) {
              setPaginateSize(size);
            },
          }}
          virtual={true}
          scroll={{
            y: "70vh",
          }}
        />
      ) : (
        <List
          itemLayout="vertical"
          size="small"
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 3,
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              key={item.title}
              actions={[
                <Button
                  key={1}
                  loading={isLoading}
                  disabled={isLoading}
                  type="text"
                  onClick={() => {
                    id.current = item.id;
                    setModalVisible(true);
                  }}
                  style={{ backgroundColor: "#C4F3F7B2" }}
                  icon={
                    <i
                      style={{ color: "green", fontSize: "large" }}
                      className="fa-duotone fa-pen-to-square"
                    ></i>
                  }
                />,
                <Popconfirm
                  key={2}
                  placement="left"
                  title={"Kayıdı silinecek onaylıyormusunuz ?"}
                  okText="Evet"
                  cancelText="Hayır"
                  onConfirm={async () => {
                    setIsLoading(true);
                    let response = await menuProductService.deleteProduct(
                      item.id
                    );
                    if (response) {
                      if (item.photo !== undefined && item.photo !== "") {
                        await fileUploadService.deleteFile(item.photo);
                      }
                      toast.success("Silme başarılı");
                    }
                    await getProduct();
                    setIsLoading(false);
                  }}
                >
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    danger
                    icon={<i className="fa-duotone fa-trash"></i>}
                  />
                </Popconfirm>,
                item.price + convertToUnicode(company?.currency?.symbol ?? ""),
                isLoading === false ? (
                  <Switch
                    checkedChildren="Aktif"
                    disabled
                    unCheckedChildren="Pasif"
                    defaultChecked={item.status}
                  />
                ) : (
                  <></>
                ),
              ]}
              extra={
                <img
                  className="ant-list-item-img"
                  alt="logo"
                  src={
                    item.photo
                      ? ApiUrl + `/` + "/" + item.photo
                      : "/images/product.png"
                  }
                />
              }
            >
              <List.Item.Meta title={item.title} description={item.detail} />
            </List.Item>
          )}
        />
      )}

      {modalVisible ? (
        <Modal closeIcon={<></>} footer={<></>} open={modalVisible}>
          <CompManagerMenuProductAdd
            isLoading={isLoading}
            deleteMenuProductImage={deleteMenuProductImage}
            categoryList={categoryList}
            closeModal={() => setModalVisible(false)}
            saveProduct={saveProduct}
            saveCategory={saveCategory}
            productService={menuProductService}
            id={id.current}
          />
        </Modal>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompManagerMenuProductList;
