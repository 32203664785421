import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UserService } from "../../../services/users/user.service";

import { CompanyUserService } from "../../../services/company_users/company_user.service";
import { UserListModel } from "../../../services/users/models/user_list.model";
import { Col, Row, Spin, Transfer } from "antd";
import { useParams } from "react-router-dom";
import { UserTypeEnum } from "../../../enums/user_type.enum";

import BackButton from "../../../components/BackButton";
import Title from "antd/es/typography/Title";

const AdminCompanyUserTransfer: React.FC = () => {
  const { companyId } = useParams();
  const userService = useMemo(() => new UserService(), []);
  const companyUserService = useMemo(() => new CompanyUserService(), []);

  const [userList, setUserList] = useState<UserListModel[]>([]);
  const [companyUserList, setCompanyUserList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  const getUserList = useCallback(async () => {
    let response = await userService.list();
    if (response) {
      setUserList(response.filter((x) => x.groupName !== UserTypeEnum.Admin));
    }
  }, [userService]);
  const getCompanyUserList = useCallback(
    async (companyId: number) => {
      let response = await companyUserService.getCompanyUserListByCompanyId(
        companyId
      );
      if (response) {
        let list = response.map((x) => x.userId.toString());
        setCompanyUserList(list);
      }
    },
    [companyUserService]
  );
  const init = useCallback(
    async (companyId: number) => {
      setIsLoading(true);
      await getUserList();
      await getCompanyUserList(companyId);
      setIsLoading(false);
    },
    [getCompanyUserList, getUserList]
  );

  useEffect(() => {
    let companyIdP = parseInt(companyId ?? "0");
    if (companyIdP !== 0) {
      setCount((co) => {
        if (co === 0) {
          init(companyIdP);
        }
        return 1;
      });
    }
    return () => {};
  }, [companyId, init]);

  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <BackButton />
        </Col>
        <Col flex={1}>
          <Title
            level={3}
            style={{ color: "#4A5967", textAlign: "center", margin: 0 }}
          >
            Firma Kullanıcı Tanımları
          </Title>
        </Col>
      </Row>
      <Spin tip="Yükleniyor" spinning={isLoading}>
        <Transfer
          dataSource={userList}
          targetKeys={companyUserList}
          style={{ flexWrap: "wrap" }}
          titles={[
            <div>Kullanıcı Listesi</div>,
            <div>Firma Kullanıcı Listesi</div>,
          ]}
          rowKey={(value) => value.id.toString()}
          onChange={async (value, direction, moveKeys) => {
            setIsLoading(true);
            let companyIdP = parseInt(companyId ?? "0");
            let numberIdList: number[] = [];
            moveKeys.forEach((element) => {
              let idP = parseInt(element.toString());
              if (idP) {
                numberIdList.push(idP);
              }
            });
            if (direction === "right") {
              if (companyIdP !== 0) {
                await companyUserService.addCompanyUserList({
                  companyId: companyIdP,
                  userIdList: numberIdList,
                });
                await getCompanyUserList(companyIdP);
              }
              //setGroupOperationClaim(moveKeys);
            }
            if (direction === "left") {
              if (companyIdP !== 0) {
                await companyUserService.deleteCompanyUserList({
                  companyId: companyIdP,
                  userIdList: numberIdList,
                });
                await getCompanyUserList(companyIdP);
              }
            }
            setIsLoading(false);
          }}
          render={(item) => item?.userName}
          pagination
        />
      </Spin>
    </div>
  );
};

export default AdminCompanyUserTransfer;
